/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AppEnum } from '../models/AppEnum';
import type { UserLimit } from '../models/UserLimit';
import type { UserLimits } from '../models/UserLimits';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class UserLimitsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * All User Limits
     * @returns UserLimits successful
     * @throws ApiError
     */
    public allLimits(): CancelablePromise<UserLimits> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/user_limits/all',
            errors: {
                401: `unauthorized`,
            },
        });
    }
    /**
     * List of Users Limits
     * @returns UserLimit successful
     * @throws ApiError
     */
    public limits({
        objType,
    }: {
        objType: AppEnum,
    }): CancelablePromise<UserLimit> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/user_limits',
            query: {
                'objType': objType,
            },
            errors: {
                401: `unauthorized`,
                404: `not found`,
            },
        });
    }
}
