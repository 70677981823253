/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AiFeedbackResult } from '../models/AiFeedbackResult';
import type { AiFeedbackResultInput } from '../models/AiFeedbackResultInput';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class AiFeedbackResultsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Create AI Feedback Result
     * @returns AiFeedbackResult successful
     * @throws ApiError
     */
    public createAiFeedbackResult({
        feedbackId,
        requestBody,
    }: {
        feedbackId: string,
        requestBody: {
            feedbackResult?: AiFeedbackResultInput;
        },
    }): CancelablePromise<AiFeedbackResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/ai/feedbacks/{feedbackId}/feedback_results',
            path: {
                'feedbackId': feedbackId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `unauthorized`,
            },
        });
    }
    /**
     * Delete AI Feedback Result
     * @returns void
     * @throws ApiError
     */
    public deleteAiFeedbackResult({
        id,
    }: {
        id: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/ai/feedback_results/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `unauthorized`,
                403: `forbidden`,
                404: `not found`,
            },
        });
    }
    /**
     * AI Feedback Result Details
     * @returns AiFeedbackResult successful
     * @throws ApiError
     */
    public getAiFeedbackResult({
        id,
    }: {
        id: string,
    }): CancelablePromise<AiFeedbackResult> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/ai/feedback_results/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `unauthorized`,
                403: `forbidden`,
                404: `not found`,
            },
        });
    }
    /**
     * Update AI Feedback Result
     * @returns AiFeedbackResult successful
     * @throws ApiError
     */
    public updateAiFeedbackResult({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: {
            feedbackResult?: AiFeedbackResultInput;
        },
    }): CancelablePromise<AiFeedbackResult> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/ai/feedback_results/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `unauthorized`,
                403: `forbidden`,
                404: `not found`,
            },
        });
    }
}
