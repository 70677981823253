/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AppEnum } from '../models/AppEnum';
import type { Template } from '../models/Template';
import type { TemplateKindEnum } from '../models/TemplateKindEnum';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class TemplatesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Create Template
     * @returns void
     * @throws ApiError
     */
    public createTemplate({
        objId,
        objType,
        tabId,
    }: {
        objId: string,
        objType: AppEnum,
        tabId?: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/templates',
            query: {
                'objId': objId,
                'objType': objType,
                'tabId': tabId,
            },
            errors: {
                401: `unauthorized`,
                404: `not found`,
            },
        });
    }
    /**
     * List of Templates
     * @returns Template successful
     * @throws ApiError
     */
    public listTemplates({
        objType,
        kind,
        page = 1,
        limit = 10,
        international,
    }: {
        objType: AppEnum,
        kind: TemplateKindEnum,
        page?: number,
        limit?: number,
        international?: boolean,
    }): CancelablePromise<Array<Template>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/templates',
            query: {
                'page': page,
                'limit': limit,
                'objType': objType,
                'kind': kind,
                'international': international,
            },
            errors: {
                401: `unauthorized`,
            },
        });
    }
}
