/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AiAssistantFile } from '../models/AiAssistantFile';
import type { AiAssistantFileInput } from '../models/AiAssistantFileInput';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class AiAssistantFilesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Create AI Assistant File
     * @returns AiAssistantFile successful
     * @throws ApiError
     */
    public createAssistantFile({
        assistantId,
        requestBody,
    }: {
        assistantId: string,
        requestBody: {
            assistant_file?: AiAssistantFileInput;
        },
    }): CancelablePromise<AiAssistantFile> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/ai/assistants/{assistant_id}/assistant_files',
            path: {
                'assistant_id': assistantId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `unauthorized`,
            },
        });
    }
    /**
     * Delete AI AssistantFile
     * @returns void
     * @throws ApiError
     */
    public deleteAiAssistantFile({
        assistantId,
        id,
    }: {
        /**
         * ID of the assistant
         */
        assistantId: string,
        /**
         * ID of the assistant file
         */
        id: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/ai/assistants/{assistant_id}/assistant_files/{id}',
            path: {
                'assistant_id': assistantId,
                'id': id,
            },
            errors: {
                404: `not found`,
            },
        });
    }
}
