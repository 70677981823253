/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Folder } from '../models/Folder';
import type { FolderInput } from '../models/FolderInput';
import type { PaginatedFolders } from '../models/PaginatedFolders';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class FoldersService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Create Folder
     * @returns Folder successful
     * @throws ApiError
     */
    public createFolder({
        requestBody,
    }: {
        requestBody: {
            folder?: FolderInput;
        },
    }): CancelablePromise<Folder> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/folders',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `unauthorized`,
            },
        });
    }
    /**
     * List of Folders
     * @returns PaginatedFolders filter by folder_id
     * @throws ApiError
     */
    public listFolders({
        page = 1,
        limit = 10,
        folderId,
        root,
    }: {
        page?: number,
        limit?: number,
        folderId?: string,
        root?: boolean,
    }): CancelablePromise<PaginatedFolders> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/folders',
            query: {
                'page': page,
                'limit': limit,
                'folder_id': folderId,
                'root': root,
            },
            errors: {
                401: `unauthorized`,
            },
        });
    }
    /**
     * Delete Folder
     * @returns void
     * @throws ApiError
     */
    public deleteFolder({
        id,
    }: {
        id: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/folders/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `unauthorized`,
                404: `not_found`,
            },
        });
    }
    /**
     * Folder Details
     * @returns Folder successful
     * @throws ApiError
     */
    public getFolder({
        id,
    }: {
        id: string,
    }): CancelablePromise<Folder> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/folders/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `unauthorized`,
                404: `not_found`,
            },
        });
    }
    /**
     * Update Folder
     * @returns Folder successful
     * @throws ApiError
     */
    public updateFolder({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: {
            folder?: FolderInput;
        },
    }): CancelablePromise<Folder> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/folders/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `unauthorized`,
                404: `not_found`,
            },
        });
    }
}
