import { flash } from "@/utils/flash";

export const useFlashMessages = () => {
  const flashStore = useFlashStore();

  onMounted(() => {
    flashStore.messages.forEach((message) => {
      flash(message.type, message.message);
      flashStore.removeMessage(message.id);
    });
  });
};
