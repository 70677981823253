import { FobizzPlatformApi } from "@/services/fobizzPlatformApi";

const apiClient = new FobizzPlatformApi({
  BASE: `${window.location.protocol}//${window.location.host}`,
  WITH_CREDENTIALS: true,
  HEADERS: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export const usePlatformApiClient = () => ({
  apiClient,
});
