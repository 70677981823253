/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PupilFeedbackAssignment } from '../models/PupilFeedbackAssignment';
import type { PupilFeedbackAssignmentInput } from '../models/PupilFeedbackAssignmentInput';
import type { PupilFeedbackFeedback } from '../models/PupilFeedbackFeedback';
import type { PupilFeedbackFeedbackCreateInput } from '../models/PupilFeedbackFeedbackCreateInput';
import type { PupilFeedbackResponse } from '../models/PupilFeedbackResponse';
import type { PupilFeedbackResponseInput } from '../models/PupilFeedbackResponseInput';
import type { PupilFeedbackResponses } from '../models/PupilFeedbackResponses';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class PupilFeedbackService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Assignment Details
     * @returns PupilFeedbackAssignment successful
     * @throws ApiError
     */
    public getPupilFeedbackAssignment({
        id,
        token,
        publicToken,
        password,
    }: {
        id: string,
        token?: string,
        publicToken?: string,
        password?: string,
    }): CancelablePromise<PupilFeedbackAssignment> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/pupil_feedback/assignments/{id}',
            path: {
                'id': id,
            },
            query: {
                'token': token,
                'public_token': publicToken,
                'password': password,
            },
            errors: {
                400: `bad request`,
                401: `unauthorized`,
                403: `forbidden`,
                404: `not found`,
            },
        });
    }
    /**
     * Update Assignment
     * @returns PupilFeedbackAssignment successful
     * @throws ApiError
     */
    public updatePupilFeedbackAssignment({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: {
            assignment?: PupilFeedbackAssignmentInput;
        },
    }): CancelablePromise<PupilFeedbackAssignment> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/pupil_feedback/assignments/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `unauthorized`,
                403: `forbidden`,
                404: `not found`,
            },
        });
    }
    /**
     * Create a Pupil Feedback Feedback
     * @returns PupilFeedbackFeedback created
     * @throws ApiError
     */
    public createPupilFeedbackFeedback({
        responseId,
        requestBody,
    }: {
        responseId: string,
        requestBody: {
            feedback?: PupilFeedbackFeedbackCreateInput;
        },
    }): CancelablePromise<PupilFeedbackFeedback> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/pupil_feedback/responses/{responseId}/feedbacks',
            path: {
                'responseId': responseId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Finalize Feedback
     * @returns PupilFeedbackFeedback successful
     * @throws ApiError
     */
    public finalizePupilFeedbackFeedback({
        id,
    }: {
        id: string,
    }): CancelablePromise<PupilFeedbackFeedback> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/pupil_feedback/feedbacks/{id}/finalize',
            path: {
                'id': id,
            },
            errors: {
                401: `unauthorized`,
                404: `not found`,
            },
        });
    }
    /**
     * Create a Pupil Feedback Response
     * @returns PupilFeedbackResponse created
     * @throws ApiError
     */
    public createPupilFeedbackResponse({
        assignmentId,
        requestBody,
    }: {
        assignmentId: string,
        requestBody: {
            response?: PupilFeedbackResponseInput;
        },
    }): CancelablePromise<PupilFeedbackResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/pupil_feedback/assignments/{assignmentId}/responses',
            path: {
                'assignmentId': assignmentId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * List of Pupil Feedback Responses
     * @returns PupilFeedbackResponses successful
     * @throws ApiError
     */
    public listPupilFeedbackResponses({
        assignmentId,
        page = 1,
        limit = 10,
    }: {
        assignmentId: string,
        page?: number,
        limit?: number,
    }): CancelablePromise<PupilFeedbackResponses> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/pupil_feedback/assignments/{assignmentId}/responses',
            path: {
                'assignmentId': assignmentId,
            },
            query: {
                'page': page,
                'limit': limit,
            },
            errors: {
                401: `unauthorized`,
                404: `not found`,
            },
        });
    }
    /**
     * Delete Response
     * @returns void
     * @throws ApiError
     */
    public deletePupilFeedbackResponse({
        id,
    }: {
        id: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/pupil_feedback/responses/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `unauthorized`,
                403: `forbidden`,
                404: `not found`,
            },
        });
    }
    /**
     * Get a Pupil Feedback Response
     * @returns PupilFeedbackResponse successful
     * @throws ApiError
     */
    public getPupilFeedbackResponse({
        id,
    }: {
        id: string,
    }): CancelablePromise<PupilFeedbackResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/pupil_feedback/responses/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `not found`,
            },
        });
    }
}
