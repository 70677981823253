import { RouteLocation } from "vue-router";

export function useOnboardingCheck() {
  const urlsStore = useUrlsStore();
  const userStore = useUserStore();

  const checkOnboarding = (to?: Partial<RouteLocation>) => {
    if (!userStore.platformUser || userStore.isIncognito) {
      return false;
    }

    if (userStore.platformUser.needsFirstOnboarding) {
      return redirectToOnboarding();
    } else if (
      userStore.platformUser.needsReOnboarding &&
      userStore.platformUser.canQuestionReOnboarding &&
      to?.name === "home"
    ) {
      return redirectToReonboarding();
    }

    return false;
  };

  const redirectToOnboarding = () => {
    const url = urlsStore.getUrl("onboarding_url");

    if (!userStore.platformUser || !url) {
      return false;
    }

    window.location.href = addRedirectToUrl(url);
    return true;
  };

  const redirectToReonboarding = () => {
    const url = urlsStore.getUrl("re_onboarding_url");
    if (!userStore.platformUser || !url) {
      return false;
    }

    window.location.href = addRedirectToUrl(url);
    return true;
  };

  const addRedirectToUrl = (url: string) => {
    const redirectUrl = new URL(url);
    redirectUrl.searchParams.set("redirect_to", window.location.href);
    return redirectUrl.toString();
  };

  return { checkOnboarding };
}
