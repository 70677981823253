/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Message } from '../models/Message';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class SessionsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Logout Session
     * @returns Message successful
     * @throws ApiError
     */
    public deleteSession(): CancelablePromise<Message> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/sessions',
            errors: {
                401: `unauthorized`,
            },
        });
    }
    /**
     * enter incognito mode
     * @returns void
     * @throws ApiError
     */
    public enterIncognito({
        id,
    }: {
        id: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/sessions/incognito/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `unauthorized`,
                403: `forbidden`,
                404: `not found`,
            },
        });
    }
    /**
     * Exit Incognito Mode
     * @returns void
     * @throws ApiError
     */
    public exitIncognitoMode(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/sessions/incognito',
            errors: {
                401: `unauthorized`,
            },
        });
    }
}
