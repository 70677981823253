/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AiFeedback } from '../models/AiFeedback';
import type { AiFeedbackInput } from '../models/AiFeedbackInput';
import type { PaginatedAiFeedbackResults } from '../models/PaginatedAiFeedbackResults';
import type { PaginatedAiFeedbacks } from '../models/PaginatedAiFeedbacks';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class AiFeedbacksService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * List of AI Feedback Results
     * @returns PaginatedAiFeedbackResults successful
     * @throws ApiError
     */
    public listAiFeedbackResults({
        feedbackId,
        page = 1,
        limit = 10,
    }: {
        feedbackId: string,
        page?: number,
        limit?: number,
    }): CancelablePromise<PaginatedAiFeedbackResults> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/ai/feedbacks/{feedbackId}/feedback_results',
            path: {
                'feedbackId': feedbackId,
            },
            query: {
                'page': page,
                'limit': limit,
            },
            errors: {
                401: `unauthorized`,
            },
        });
    }
    /**
     * Create AI Feedback
     * @returns AiFeedback successful
     * @throws ApiError
     */
    public createAiFeedback({
        requestBody,
    }: {
        requestBody: {
            feedback?: AiFeedbackInput;
        },
    }): CancelablePromise<AiFeedback> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/ai/feedbacks',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad request`,
                401: `unauthorized`,
            },
        });
    }
    /**
     * List of AI Feedbacks
     * @returns PaginatedAiFeedbacks successful
     * @throws ApiError
     */
    public listAiFeedbacks({
        page = 1,
        limit = 10,
    }: {
        page?: number,
        limit?: number,
    }): CancelablePromise<PaginatedAiFeedbacks> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/ai/feedbacks',
            query: {
                'page': page,
                'limit': limit,
            },
            errors: {
                401: `unauthorized`,
            },
        });
    }
    /**
     * Delete AI Feedback
     * @returns void
     * @throws ApiError
     */
    public deleteAiFeedback({
        id,
    }: {
        id: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/ai/feedbacks/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `unauthorized`,
                404: `not found`,
            },
        });
    }
    /**
     * AI Feedback Details
     * @returns AiFeedback successful
     * @throws ApiError
     */
    public getAiFeedback({
        id,
    }: {
        id: string,
    }): CancelablePromise<AiFeedback> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/ai/feedbacks/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `unauthorized`,
                404: `not found`,
            },
        });
    }
    /**
     * Update AI Feedback
     * @returns AiFeedback successful
     * @throws ApiError
     */
    public updateAiFeedback({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: {
            feedback?: AiFeedbackInput;
        },
    }): CancelablePromise<AiFeedback> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/ai/feedbacks/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `unauthorized`,
                404: `not found`,
            },
        });
    }
}
