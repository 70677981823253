/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum AppEnum {
    AI_CONVERSATION = 'Ai::Conversation',
    AI_MINI_TOOL = 'Ai::MiniTool',
    AI_IMAGE = 'Ai::Image',
    AI_SPEECH_RECOGNITION = 'Ai::SpeechRecognition',
    AI_PERSONA = 'Ai::Persona',
    AI_DOCUMENT_QA = 'Ai::DocumentQa',
    AI_ASSISTANT = 'Ai::Assistant',
    AI_FEEDBACK = 'Ai::Feedback',
    AI_IMAGE_RECOGNITION = 'Ai::ImageRecognition',
    AI_CONVERTER = 'Ai::Converter',
    WORKSHEET_WORKSHEET_WIZARD = 'Worksheet::WorksheetWizard',
    PUPIL_FEEDBACK_ASSIGNMENT = 'PupilFeedback::Assignment',
    WORKSHEET_WORKSHEET = 'Worksheet::Worksheet',
    CLASSROOM_SCREEN_SCREEN = 'ClassroomScreen::Screen',
    PINBOARD_BOARD = 'Pinboard::Board',
    SURVEYS_SURVEY = 'Surveys::Survey',
    TEXT_EDITOR_DOCUMENT = 'TextEditor::Document',
    WEBSITE_PAGE = 'Website::Page',
    VIDEO_RECORDER_FILE = 'VideoRecorder::File',
    AUDIO_RECORDER_FILE = 'AudioRecorder::File',
    SCREEN_RECORDER_FILE = 'ScreenRecorder::File',
    WORD_CLOUDS_WORD_CLOUD = 'WordClouds::WordCloud',
    LINK_SHORTENER_LINK = 'LinkShortener::Link',
    FILE_SHARING_FILE = 'FileSharing::File',
    QRCODES_QRCODE = 'Qrcodes::Qrcode',
    SCHOOL_CLASS = 'SchoolClass',
    PROJECT = 'Project',
}
