/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AiChatCompletion } from '../models/AiChatCompletion';
import type { AiChatCompletionInput } from '../models/AiChatCompletionInput';
import type { PaginatedAiChatCompletions } from '../models/PaginatedAiChatCompletions';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class AiChatCompletionsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Cancel AI Chat Completion
     * @returns void
     * @throws ApiError
     */
    public cancelAiChatCompletion({
        id,
    }: {
        id: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/ai/chat_completions/{id}/cancel',
            path: {
                'id': id,
            },
            errors: {
                401: `unauthorized`,
                404: `not found`,
            },
        });
    }
    /**
     * Create AI Chat Completion
     * @returns AiChatCompletion successful
     * @throws ApiError
     */
    public createAiChatCompletion({
        requestBody,
    }: {
        requestBody: {
            chat_completion?: AiChatCompletionInput;
        },
    }): CancelablePromise<AiChatCompletion> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/ai/chat_completions',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `unauthorized`,
                422: `unprocessable entity`,
            },
        });
    }
    /**
     * List of AI Chat Completions
     * @returns PaginatedAiChatCompletions successful
     * @throws ApiError
     */
    public listAiChatCompletions(): CancelablePromise<PaginatedAiChatCompletions> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/ai/chat_completions',
            errors: {
                401: `unauthorized`,
            },
        });
    }
    /**
     * Delete AI Chat Completion
     * @returns void
     * @throws ApiError
     */
    public deleteAiChatCompletion({
        id,
    }: {
        id: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/ai/chat_completions/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `unauthorized`,
                404: `not found`,
            },
        });
    }
    /**
     * AI Chat Completion Details
     * @returns AiChatCompletion successful
     * @throws ApiError
     */
    public getAiChatCompletion({
        id,
    }: {
        id: string,
    }): CancelablePromise<AiChatCompletion> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/ai/chat_completions/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `unauthorized`,
                404: `not found`,
            },
        });
    }
    /**
     * Update AI Chat Completion
     * @returns AiChatCompletion successful
     * @throws ApiError
     */
    public updateAiChatCompletion({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: {
            chat_completion?: AiChatCompletionInput;
        },
    }): CancelablePromise<AiChatCompletion> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/ai/chat_completions/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `unauthorized`,
                404: `not found`,
            },
        });
    }
}
