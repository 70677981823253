import { FlashKindEnum } from "@/utils/flash";
import { defineStore } from "pinia";
import { v4 as uuidv4 } from "uuid";

export type FlashMessage = {
  id: string;
  message: string;
  type: FlashKindEnum;
};
export type CreateFlashMessage = Omit<FlashMessage, "id">;

type FlashMessageStoreType = {
  messages: FlashMessage[];
};

const messageType = (type: string): FlashMessage["type"] => {
  {
    if (type === "alert") {
      return FlashKindEnum.ALERT;
    }

    if (type === "success") {
      return FlashKindEnum.SUCCESS;
    }

    return FlashKindEnum.INFO;
  }
};

export const useFlashStore = defineStore("flash", {
  state: (): FlashMessageStoreType => {
    return {
      messages: [],
    };
  },
  actions: {
    addMessage(message: CreateFlashMessage) {
      this.messages.push({
        ...message,
        type: messageType(message.type),
        id: uuidv4(),
      });
    },
    removeMessage(id: string) {
      const index = this.messages.findIndex((message) => message.id === id);
      if (index > -1) {
        this.messages.splice(index, 1);
      }
    },
  },
});
