import { FobizzToolsApi } from "@/services/fobizzToolsApi";

const apiClient = new FobizzToolsApi({
  BASE: `${window.location.protocol}//${window.location.host}`,
  WITH_CREDENTIALS: true,
  HEADERS: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export const useApiClient = () => ({
  apiClient,
  ...apiClient,
});
