/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AiMiniTool } from '../models/AiMiniTool';
import type { AiMiniToolInput } from '../models/AiMiniToolInput';
import type { PaginatedAiMiniTools } from '../models/PaginatedAiMiniTools';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class AiMiniToolsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Create AI MiniTool
     * @returns AiMiniTool successful
     * @throws ApiError
     */
    public createMiniTool({
        requestBody,
    }: {
        requestBody: {
            miniToolRequest?: AiMiniToolInput;
        },
    }): CancelablePromise<AiMiniTool> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/ai/mini_tools',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad request`,
                401: `unauthorized`,
                422: `unprocessable entity`,
            },
        });
    }
    /**
     * List of AI MiniTools
     * @returns PaginatedAiMiniTools successful
     * @throws ApiError
     */
    public listAiMiniTools({
        page = 1,
        limit = 10,
        query,
        category,
        international,
    }: {
        page?: number,
        limit?: number,
        query?: string,
        category?: string,
        international?: string,
    }): CancelablePromise<PaginatedAiMiniTools> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/ai/mini_tools',
            query: {
                'page': page,
                'limit': limit,
                'query': query,
                'category': category,
                'international': international,
            },
        });
    }
    /**
     * Delete AI MiniTool
     * @returns any successful
     * @throws ApiError
     */
    public deleteAiMiniTool({
        id,
    }: {
        id: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/ai/mini_tools/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `unauthorized`,
                404: `not found`,
            },
        });
    }
    /**
     * AI MiniTool
     * @returns AiMiniTool successful
     * @throws ApiError
     */
    public getAiMiniTool({
        id,
    }: {
        id: string,
    }): CancelablePromise<AiMiniTool> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/ai/mini_tools/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `unauthorized`,
                404: `not found`,
            },
        });
    }
    /**
     * List of featured AI MiniTools
     * @returns PaginatedAiMiniTools successful
     * @throws ApiError
     */
    public listFeaturedAiMiniTools({
        international,
        page = 1,
        limit = 10,
    }: {
        international?: string,
        page?: number,
        limit?: number,
    }): CancelablePromise<PaginatedAiMiniTools> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/ai/mini_tools/featured',
            query: {
                'international': international,
                'page': page,
                'limit': limit,
            },
        });
    }
}
