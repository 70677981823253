import "@/main.ts";
import * as ActiveStorage from "@rails/activestorage";
import ahoy from "ahoy.js";
import "~/stylesheets/application.scss";

ActiveStorage.start();

ahoy.configure({
  cookies: false,
});

// analytics
ahoy.trackClicks("[data-click-tracking=true]");
