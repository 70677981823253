/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Collection } from '../models/Collection';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class CollectionsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * List of Collections
     * @returns Collection successful filtered on international false
     * @throws ApiError
     */
    public listCollections({
        page = 1,
        limit = 12,
        international,
    }: {
        page?: number,
        limit?: number,
        international?: boolean,
    }): CancelablePromise<Array<Collection>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/collections',
            query: {
                'page': page,
                'limit': limit,
                'international': international,
            },
        });
    }
    /**
     * List of recommended Collections
     * @returns Collection successful fallback
     * @throws ApiError
     */
    public listRecommendedCollections({
        id,
    }: {
        id: string,
    }): CancelablePromise<Array<Collection>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/collections/{id}/recommendations',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Collection Details
     * @returns Collection successful for unpublished collection as admin
     * @throws ApiError
     */
    public getCollection({
        id,
    }: {
        id: string,
    }): CancelablePromise<Collection> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/collections/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `not successful for unpublished collection as non admin`,
            },
        });
    }
}
