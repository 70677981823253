/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginatedItems } from '../models/PaginatedItems';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class DiscardedItemsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Delete Discarded Items
     * @returns void
     * @throws ApiError
     */
    public deleteDiscardedItems({
        tabId,
    }: {
        tabId?: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/discarded_items',
            query: {
                'tabId': tabId,
            },
            errors: {
                401: `unauthorized`,
            },
        });
    }
    /**
     * List of Discarded Items
     * @returns PaginatedItems filter by query
     * @throws ApiError
     */
    public listDiscardedItems({
        query,
        page = 1,
        limit = 10,
    }: {
        query?: string,
        page?: number,
        limit?: number,
    }): CancelablePromise<PaginatedItems> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/discarded_items',
            query: {
                'query': query,
                'page': page,
                'limit': limit,
            },
            errors: {
                401: `unauthorized`,
            },
        });
    }
}
