/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AiPersona } from '../models/AiPersona';
import type { AiPersonaInput } from '../models/AiPersonaInput';
import type { PaginatedAiPersonas } from '../models/PaginatedAiPersonas';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class AiPersonasService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Create AI Personas
     * @returns AiPersona successful
     * @throws ApiError
     */
    public createAiPersona({
        requestBody,
    }: {
        requestBody: {
            persona?: AiPersonaInput;
        },
    }): CancelablePromise<AiPersona> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/ai/personas',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `unauthorized`,
            },
        });
    }
    /**
     * List of AI Personas
     * @returns PaginatedAiPersonas successful
     * @throws ApiError
     */
    public listAiPersonas({
        page = 1,
        limit = 10,
        query,
    }: {
        page?: number,
        limit?: number,
        query?: string,
    }): CancelablePromise<PaginatedAiPersonas> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/ai/personas',
            query: {
                'page': page,
                'limit': limit,
                'query': query,
            },
            errors: {
                401: `unauthorized`,
            },
        });
    }
    /**
     * Delete AI Personas
     * @returns void
     * @throws ApiError
     */
    public deleteAiPersonas({
        id,
    }: {
        id: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/ai/personas/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `unauthorized`,
                404: `not found`,
            },
        });
    }
    /**
     * Get AI Personas Details
     * @returns AiPersona successful
     * @throws ApiError
     */
    public getAiPersona({
        id,
    }: {
        id: string,
    }): CancelablePromise<AiPersona> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/ai/personas/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `unauthorized`,
                404: `not found`,
            },
        });
    }
    /**
     * Update AI Personas
     * @returns AiPersona successful
     * @throws ApiError
     */
    public updateAiPersona({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: {
            persona?: AiPersonaInput;
        },
    }): CancelablePromise<AiPersona> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/ai/personas/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `unauthorized`,
                404: `not found`,
            },
        });
    }
}
