import { defineStore } from "pinia";

type IsGalleryStoreType = {
  isGalleryMode: boolean;
};

export const useIsGalleryModeStore = defineStore("isGalleryMode", {
  state: (): IsGalleryStoreType => ({ isGalleryMode: false }),
  actions: {
    updateGalleryMode(isGalleryMode: boolean) {
      this.isGalleryMode = isGalleryMode;
    },
  },
});
