/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginatedVideoRecorderFiles } from '../models/PaginatedVideoRecorderFiles';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class VideoRecorderFilesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * List of Files
     * @returns PaginatedVideoRecorderFiles successful
     * @throws ApiError
     */
    public listVideoRecorderFiles(): CancelablePromise<PaginatedVideoRecorderFiles> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/video_recorder/files',
            errors: {
                401: `unauthorized`,
            },
        });
    }
}
