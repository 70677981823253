/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AiImageRecognition } from '../models/AiImageRecognition';
import type { AiImageRecognitionInput } from '../models/AiImageRecognitionInput';
import type { PaginatedAiImageRecognitions } from '../models/PaginatedAiImageRecognitions';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class AiImageRecognitionsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Create AI ImageRecognition
     * @returns AiImageRecognition successful
     * @throws ApiError
     */
    public createImageRecognition({
        requestBody,
    }: {
        requestBody: {
            imageRecognition?: AiImageRecognitionInput;
        },
    }): CancelablePromise<AiImageRecognition> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/ai/image_recognitions',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad request`,
                401: `unauthorized`,
            },
        });
    }
    /**
     * List of AI ImageRecognition
     * @returns PaginatedAiImageRecognitions successful
     * @throws ApiError
     */
    public listAiImageRecognition({
        page = 1,
        limit = 10,
        query,
    }: {
        page?: number,
        limit?: number,
        query?: string,
    }): CancelablePromise<PaginatedAiImageRecognitions> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/ai/image_recognitions',
            query: {
                'page': page,
                'limit': limit,
                'query': query,
            },
            errors: {
                401: `unauthorized`,
            },
        });
    }
    /**
     * Delete AI ImageRecognition
     * @returns void
     * @throws ApiError
     */
    public deleteAiImageRecognition({
        id,
    }: {
        id: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/ai/image_recognitions/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `unauthorized`,
                404: `not found`,
            },
        });
    }
    /**
     * AI ImageRecognition Details
     * @returns AiImageRecognition successful
     * @throws ApiError
     */
    public getAiImageRecognition({
        id,
    }: {
        id: string,
    }): CancelablePromise<AiImageRecognition> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/ai/image_recognitions/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `unauthorized`,
                404: `not found`,
            },
        });
    }
    /**
     * Update AI ImageRecognition
     * @returns AiImageRecognition successful
     * @throws ApiError
     */
    public updateAiImageRecognition({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: {
            imageRecognition?: AiImageRecognitionInput;
        },
    }): CancelablePromise<AiImageRecognition> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/ai/image_recognitions/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad request`,
                401: `unauthorized`,
                404: `not found`,
            },
        });
    }
}
