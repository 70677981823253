/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AppBookmarkableEnum } from '../models/AppBookmarkableEnum';
import type { BookmarkStatus } from '../models/BookmarkStatus';
import type { PaginatedBookmarks } from '../models/PaginatedBookmarks';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class BookmarksService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Check Bookmark Status
     * @returns BookmarkStatus successful
     * @throws ApiError
     */
    public checkBookmark({
        objId,
        objType,
    }: {
        objId: string,
        objType: AppBookmarkableEnum,
    }): CancelablePromise<BookmarkStatus> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/bookmarks/check',
            query: {
                'objId': objId,
                'objType': objType,
            },
            errors: {
                401: `unauthorized`,
            },
        });
    }
    /**
     * Delete Bookmark
     * @returns void
     * @throws ApiError
     */
    public deleteBookmark({
        id,
    }: {
        id: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/bookmarks/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `unauthorized`,
                404: `not found`,
            },
        });
    }
    /**
     * List of Bookmarks
     * @returns PaginatedBookmarks successful
     * @throws ApiError
     */
    public listBookmarks({
        page = 1,
        limit = 10,
    }: {
        page?: number,
        limit?: number,
    }): CancelablePromise<PaginatedBookmarks> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/bookmarks',
            query: {
                'page': page,
                'limit': limit,
            },
            errors: {
                401: `unauthorized`,
            },
        });
    }
    /**
     * Toggle Bookmark
     * @returns BookmarkStatus successful
     * @throws ApiError
     */
    public toggleBookmark({
        objId,
        objType,
    }: {
        objId: string,
        objType: AppBookmarkableEnum,
    }): CancelablePromise<BookmarkStatus> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/bookmarks/toggle',
            query: {
                'objId': objId,
                'objType': objType,
            },
            errors: {
                401: `unauthorized`,
            },
        });
    }
}
