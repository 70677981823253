/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AiMessageFile } from '../models/AiMessageFile';
import type { AiMessageFileInput } from '../models/AiMessageFileInput';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class AiMessageFilesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Create AI Message File
     * @returns AiMessageFile successful
     * @throws ApiError
     */
    public createMessageFile({
        messageId,
        requestBody,
    }: {
        messageId: string,
        requestBody: {
            message_file?: AiMessageFileInput;
        },
    }): CancelablePromise<AiMessageFile> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/ai/messages/{message_id}/message_files',
            path: {
                'message_id': messageId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `unauthorized`,
            },
        });
    }
    /**
     * Destroy AI Message File
     * @returns void
     * @throws ApiError
     */
    public destroyMessageFile({
        id,
    }: {
        id: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/ai/message_files/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `unauthorized`,
                403: `unauthorized`,
                404: `not found`,
            },
        });
    }
    /**
     * Show AI Message File
     * @returns AiMessageFile successful
     * @throws ApiError
     */
    public showMessageFile({
        id,
    }: {
        id: string,
    }): CancelablePromise<AiMessageFile> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/ai/message_files/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `unauthorized`,
                403: `unauthorized`,
                404: `not found`,
            },
        });
    }
}
