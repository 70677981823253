/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AiConverter } from '../models/AiConverter';
import type { AiConverterInput } from '../models/AiConverterInput';
import type { PaginatedAiConverters } from '../models/PaginatedAiConverters';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class AiConvertersService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Create AI Converter
     * @returns any successful
     * @throws ApiError
     */
    public createAiConverter({
        requestBody,
    }: {
        requestBody: {
            converter?: AiConverterInput;
        },
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/ai/converters',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad request`,
                401: `unauthorized`,
            },
        });
    }
    /**
     * List AI Converters
     * @returns PaginatedAiConverters successful
     * @throws ApiError
     */
    public listAiConverters({
        page,
        limit,
    }: {
        page?: number,
        limit?: number,
    }): CancelablePromise<PaginatedAiConverters> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/ai/converters',
            query: {
                'page': page,
                'limit': limit,
            },
            errors: {
                401: `unauthorized`,
            },
        });
    }
    /**
     * Delete AI Converter
     * @returns void
     * @throws ApiError
     */
    public deleteAiConverter({
        id,
    }: {
        id: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/ai/converters/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `unauthorized`,
                404: `not found`,
            },
        });
    }
    /**
     * Show AI Converter
     * @returns AiConverter successful
     * @throws ApiError
     */
    public getAiConverter({
        id,
    }: {
        id: string,
    }): CancelablePromise<AiConverter> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/ai/converters/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `unauthorized`,
                404: `not found`,
            },
        });
    }
}
