/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AudioRecording } from '../models/AudioRecording';
import type { AudioRecordingCreateInput } from '../models/AudioRecordingCreateInput';
import type { AudioRecordingUpdateInput } from '../models/AudioRecordingUpdateInput';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class AudioRecordingsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Create Audio Recording
     * @returns AudioRecording successful
     * @throws ApiError
     */
    public createAudioRecording({
        requestBody,
    }: {
        requestBody: {
            recording?: AudioRecordingCreateInput;
        },
    }): CancelablePromise<AudioRecording> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/audio_recordings',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `unauthorized`,
                422: `UnexpectedResponse`,
            },
        });
    }
    /**
     * Delete Audio Recording
     * @returns void
     * @throws ApiError
     */
    public deleteAudioRecording({
        recordingId,
    }: {
        recordingId: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/audio_recordings/{recordingId}',
            path: {
                'recordingId': recordingId,
            },
            errors: {
                401: `unauthorized`,
                403: `forbidden`,
                404: `not found`,
            },
        });
    }
    /**
     * Audio Recording Details
     * @returns AudioRecording successful
     * @throws ApiError
     */
    public getAudioRecording({
        recordingId,
    }: {
        recordingId: string,
    }): CancelablePromise<AudioRecording> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/audio_recordings/{recordingId}',
            path: {
                'recordingId': recordingId,
            },
            errors: {
                401: `unauthorized`,
                403: `forbidden`,
                404: `not found`,
            },
        });
    }
    /**
     * Update Audio Recording
     * @returns AudioRecording successful
     * @throws ApiError
     */
    public updateAudioRecording({
        recordingId,
        requestBody,
    }: {
        recordingId: string,
        requestBody: {
            recording?: AudioRecordingUpdateInput;
        },
    }): CancelablePromise<AudioRecording> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/audio_recordings/{recordingId}',
            path: {
                'recordingId': recordingId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `unauthorized`,
                403: `forbidden`,
                404: `not found`,
            },
        });
    }
}
