/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { VideoRecorderFile } from '../models/VideoRecorderFile';
import type { VideoRecorderInput } from '../models/VideoRecorderInput';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class VideoRecorderService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Delete Video Recorder File
     * @returns void
     * @throws ApiError
     */
    public deleteVideoRecorderFile({
        id,
    }: {
        id: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/video_recorder/files/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `unauthorized`,
                403: `forbidden`,
                404: `not found`,
            },
        });
    }
    /**
     * Show File
     * @returns VideoRecorderFile successful
     * @throws ApiError
     */
    public showVideoRecorderFile({
        id,
        token,
        password,
    }: {
        id: string,
        token?: string,
        password?: string,
    }): CancelablePromise<VideoRecorderFile> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/video_recorder/files/{id}',
            path: {
                'id': id,
            },
            query: {
                'token': token,
                'password': password,
            },
            errors: {
                400: `bad request`,
                401: `unauthorized`,
                403: `forbidden`,
                404: `not found`,
            },
        });
    }
    /**
     * Update File
     * @returns VideoRecorderFile successful
     * @throws ApiError
     */
    public updateVideoRecorderFile({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: {
            videoRecorderFile?: VideoRecorderInput;
        },
    }): CancelablePromise<VideoRecorderFile> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/video_recorder/files/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `unauthorized`,
                403: `forbidden`,
                404: `not found`,
            },
        });
    }
    /**
     * Create File
     * @returns VideoRecorderFile successful
     * @throws ApiError
     */
    public createVideoRecorderFile(): CancelablePromise<VideoRecorderFile> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/video_recorder/files',
            errors: {
                401: `unauthorized`,
            },
        });
    }
}
