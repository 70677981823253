import { datetimeFormats } from "@/i18n/datetimeFormats";
import { loadLocaleMessages } from "@/i18n/messages";
import { LocaleEnum } from "@/services/fobizzToolsApi";
import { createI18n, I18n } from "vue-i18n";

export const generateI18n = (): I18n => {
  const userLocales = navigator.languages.map((locale) => locale.split("-")[0]);
  const locale = userLocales.find((locale) =>
    Object.values(LocaleEnum).includes(locale as LocaleEnum)
  );

  const messages = loadLocaleMessages();

  return createI18n({
    legacy: false,
    fallbackLocale: LocaleEnum.DE,
    availableLocales: Object.values(LocaleEnum),
    warnHtmlMessage: false,
    locale,
    messages,
    datetimeFormats,
  });
};
