import { defineStore } from "pinia";

type UrlsStoreType = {
  urls: {
    [key: string]: string;
  };
};

export const useUrlsStore = defineStore("urls", {
  state: (): UrlsStoreType => {
    return {
      urls: {},
    };
  },
  getters: {
    rootUrl: (store) => store.urls.root_url,
    fobizzRootUrl: (store) => store.urls.fobizz_root_url,
    loginUrl: (store) => store.urls.login_url,
    signupUrl: (store) => store.urls.signup_url,
    pricingTableUrl: (store) => store.urls.pricing_table_url,
    oauthLoginUrl: (store) => store.urls.oauth_login_url,
    oauthLogoutUrl: (store) => store.urls.oauth_logout_url,
    oauthEmbedUrl: (store) => store.urls.oauth_embed_url,
    platformCoursesUrl: (store) => store.urls.platform_courses_url,
    platformPostsUrl: (store) => store.urls.posts_url,
    platformSchoolUrl: (store) => store.urls.company_url,
    platformReviewerUrl: (store) => store.urls.platform_reviewer_url,
    platformProfileUrl: (store) => store.urls.platform_profile_url,
    platformSubscriptionUrl: (store) => store.urls.profile_subscription_url,
    platformAffiliatesUrl: (store) => store.urls.affiliate_invites_url,
    getUrl:
      (store) =>
      (name: string): string | undefined =>
        store.urls[name],
  },
});
