/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginatedSurveyResponses } from '../models/PaginatedSurveyResponses';
import type { Survey } from '../models/Survey';
import type { SurveyInput } from '../models/SurveyInput';
import type { SurveyQuestion } from '../models/SurveyQuestion';
import type { SurveyQuestionInput } from '../models/SurveyQuestionInput';
import type { SurveyQuestionMoveInput } from '../models/SurveyQuestionMoveInput';
import type { SurveyResponse } from '../models/SurveyResponse';
import type { SurveyResponseInput } from '../models/SurveyResponseInput';
import type { SurveyResults } from '../models/SurveyResults';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class SurveysService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Create Survey Question
     * @returns SurveyQuestion successful
     * @throws ApiError
     */
    public createSurveyQuestion({
        surveyId,
        requestBody,
    }: {
        surveyId: string,
        requestBody: {
            question?: SurveyQuestionInput;
        },
    }): CancelablePromise<SurveyQuestion> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/surveys/surveys/{surveyId}/questions',
            path: {
                'surveyId': surveyId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad request`,
                401: `unauthorized`,
                404: `not found`,
            },
        });
    }
    /**
     * Delete Survey Question
     * @returns void
     * @throws ApiError
     */
    public deleteSurveyQuestion({
        id,
    }: {
        id: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/surveys/questions/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `unauthorized`,
                403: `forbidden`,
                404: `not found`,
            },
        });
    }
    /**
     * Survey Question Details
     * @returns SurveyQuestion successful
     * @throws ApiError
     */
    public getSurveyQuestion({
        id,
    }: {
        id: string,
    }): CancelablePromise<SurveyQuestion> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/surveys/questions/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `unauthorized`,
                403: `forbidden`,
                404: `not found`,
            },
        });
    }
    /**
     * Update Survey Question
     * @returns SurveyQuestion successful
     * @throws ApiError
     */
    public updateSurveyQuestion({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: {
            question?: SurveyQuestionInput;
        },
    }): CancelablePromise<SurveyQuestion> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/surveys/questions/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `unauthorized`,
                403: `forbidden`,
                404: `not found`,
            },
        });
    }
    /**
     * Move Survey Question
     * @returns SurveyQuestion successful
     * @throws ApiError
     */
    public moveSurveyQuestion({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: {
            question?: SurveyQuestionMoveInput;
        },
    }): CancelablePromise<SurveyQuestion> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/surveys/questions/{id}/move',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `unauthorized`,
                403: `forbidden`,
                404: `not found`,
            },
        });
    }
    /**
     * Create Survey Response
     * @returns SurveyResponse successful
     * @throws ApiError
     */
    public createSurveyResponse({
        surveyId,
        requestBody,
    }: {
        surveyId: string,
        requestBody: {
            response?: SurveyResponseInput;
        },
    }): CancelablePromise<SurveyResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/surveys/surveys/{surveyId}/responses',
            path: {
                'surveyId': surveyId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad_request`,
                401: `unauthorized`,
                404: `not_found`,
                422: `unprocessable_entity`,
            },
        });
    }
    /**
     * List of Survey Responses
     * @returns PaginatedSurveyResponses successful
     * @throws ApiError
     */
    public surveyResponses({
        surveyId,
        page = 1,
        limit = 10,
    }: {
        surveyId: string,
        page?: number,
        limit?: number,
    }): CancelablePromise<PaginatedSurveyResponses> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/surveys/surveys/{surveyId}/responses',
            path: {
                'surveyId': surveyId,
            },
            query: {
                'page': page,
                'limit': limit,
            },
            errors: {
                401: `unauthorized`,
            },
        });
    }
    /**
     * Survey Response Details
     * @returns SurveyResponse successful
     * @throws ApiError
     */
    public getSurveyResponse({
        id,
    }: {
        id: string,
    }): CancelablePromise<SurveyResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/surveys/responses/{id}',
            path: {
                'id': id,
            },
            errors: {
                403: `forbidden`,
                404: `not found`,
            },
        });
    }
    /**
     * Survey Results
     * @returns SurveyResults successful
     * @throws ApiError
     */
    public surveyResults({
        id,
    }: {
        id: string,
    }): CancelablePromise<SurveyResults> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/surveys/surveys/{id}/results',
            path: {
                'id': id,
            },
            errors: {
                401: `unauthorized`,
                403: `forbidden`,
                404: `not found`,
            },
        });
    }
    /**
     * Survey Details
     * @returns Survey successful
     * @throws ApiError
     */
    public getSurvey({
        id,
        token,
        password,
    }: {
        id: string,
        token?: string,
        password?: string,
    }): CancelablePromise<Survey> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/surveys/surveys/{id}',
            path: {
                'id': id,
            },
            query: {
                'token': token,
                'password': password,
            },
            errors: {
                400: `bad request`,
                403: `forbidden`,
                404: `not found`,
            },
        });
    }
    /**
     * Update Survey
     * @returns Survey successful
     * @throws ApiError
     */
    public updateSurvey({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: {
            survey?: SurveyInput;
        },
    }): CancelablePromise<Survey> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/surveys/surveys/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `unauthorized`,
                403: `forbidden`,
                404: `not found`,
            },
        });
    }
}
