/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginatedWordClouds } from '../models/PaginatedWordClouds';
import type { WordCloud } from '../models/WordCloud';
import type { WordCloudAddWordInput } from '../models/WordCloudAddWordInput';
import type { WordCloudInput } from '../models/WordCloudInput';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class WordCloudService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Add Word to WordCloud
     * @returns WordCloud successful
     * @throws ApiError
     */
    public addWordCloudWord({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: {
            wordCloud?: WordCloudAddWordInput;
        },
    }): CancelablePromise<WordCloud> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/word_clouds/word_clouds/{id}/add_word',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `unauthorized`,
                403: `forbidden`,
                404: `not found`,
            },
        });
    }
    /**
     * Create WordCloud
     * @returns WordCloud successful
     * @throws ApiError
     */
    public createWordCloud(): CancelablePromise<WordCloud> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/word_clouds/word_clouds',
            errors: {
                401: `unauthorized`,
            },
        });
    }
    /**
     * List of WordClouds
     * @returns PaginatedWordClouds successful
     * @throws ApiError
     */
    public listWordClouds({
        page = 1,
        limit = 10,
    }: {
        page?: number,
        limit?: number,
    }): CancelablePromise<PaginatedWordClouds> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/word_clouds/word_clouds',
            query: {
                'page': page,
                'limit': limit,
            },
            errors: {
                401: `unauthorized`,
            },
        });
    }
    /**
     * Delete WordCloud
     * @returns void
     * @throws ApiError
     */
    public deleteWordCloud({
        id,
    }: {
        id: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/word_clouds/word_clouds/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `unauthorized`,
                404: `not found`,
            },
        });
    }
    /**
     * WordCloud Details
     * @returns WordCloud successful
     * @throws ApiError
     */
    public getWordCloud({
        id,
        token,
        password,
    }: {
        id: string,
        token?: string,
        password?: string,
    }): CancelablePromise<WordCloud> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/word_clouds/word_clouds/{id}',
            path: {
                'id': id,
            },
            query: {
                'token': token,
                'password': password,
            },
            errors: {
                400: `bad request`,
                401: `unauthorized`,
                403: `forbidden`,
                404: `not found`,
            },
        });
    }
    /**
     * Update WordCloud
     * @returns WordCloud successful
     * @throws ApiError
     */
    public updateWordCloud({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: {
            wordCloud?: WordCloudInput;
        },
    }): CancelablePromise<WordCloud> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/word_clouds/word_clouds/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `unauthorized`,
                404: `not found`,
            },
        });
    }
}
