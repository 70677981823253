// eslint-disable-next-line @typescript-eslint/ban-ts-comment
import MainEntryPointApp from "@/MainEntryPointApp.vue";
import { type User as PlatformUser } from "@/services/fobizzPlatformApi";
import { type User } from "@/services/fobizzToolsApi";
import { QueryClient, VueQueryPlugin } from "@tanstack/vue-query";
import FloatingVue from "floating-vue";
import { createPinia } from "pinia";
import { createApp } from "vue";
import { createRouter, createWebHistory } from "vue-router/auto";

import { generateI18n } from "./i18n/i18n.config";
import { CreateFlashMessage } from "./stores/flash";
import { routerBeforeEach } from "./utils/routerBeforeEach";

const initVue = async () => {
  const el = document.querySelector('[data-behavior="vue"]') as HTMLElement;
  if (el) {
    const app = createApp(MainEntryPointApp);

    app.config.warnHandler = (msg, instance, trace) => {
      // warnhandler is ignored in production, check anyways
      if (process.env.NODE_ENV !== "development") return;

      if (
        msg.includes("Failed to resolve component") ||
        msg.includes("Unknown custom element")
      ) {
        console.error("Vue Global Warn Handler:", msg, trace);
        throw new Error(msg);
      } else {
        console.warn("Vue Global Warn Handler:", msg, instance, trace);
      }
    };

    const pinia = createPinia();
    app.use(pinia);

    if (el.dataset.user) {
      const userStore = useUserStore();
      const user = JSON.parse(el.dataset.user) as User;
      userStore.$patch({ user });
    }

    if (el.dataset.platformUser) {
      const userStore = useUserStore();
      const platformUser = JSON.parse(el.dataset.platformUser) as PlatformUser;
      userStore.$patch({ platformUser });
    }

    if (el.dataset.flash) {
      const flashStore = useFlashStore();
      const messages = JSON.parse(el.dataset.flash) as string[][];
      messages.forEach((flashMessage) => {
        const [type, message] = flashMessage;
        flashStore.addMessage({ type, message } as CreateFlashMessage);
      });
    }

    if (el.dataset.apps) {
      const appsStore = useAppsStore();
      appsStore.$patch({ apps: JSON.parse(el.dataset.apps) });
    }

    if (el.dataset.urls) {
      const urlsStore = useUrlsStore();
      urlsStore.$patch({ urls: JSON.parse(el.dataset.urls) });
    }

    if (el.dataset.isGalleryMode) {
      const isGalleryModeStore = useIsGalleryModeStore();
      isGalleryModeStore.$patch({
        isGalleryMode: JSON.parse(el.dataset.isGalleryMode),
      });
    }

    const router = createRouter({
      history: createWebHistory(),
      linkExactActiveClass: "router-link-exact-active",
      linkActiveClass: "router-link-active",
      scrollBehavior(to, _from, _savedPosition) {
        if (to.hash) {
          return { el: to.hash };
        }
        // always scroll to top
        return { top: 0, left: 1 };
      },
    });

    router.beforeEach(routerBeforeEach);

    if (import.meta.env.PROD) {
      // if new app version is deployed, force reload
      // in development we don't want this behavior, because it's really annoying
      router.onError((error, to) => {
        if (
          error.message.includes(
            "Failed to fetch dynamically imported module"
          ) ||
          error.message.includes("error loading dynamically imported module") ||
          error.message.includes("Importing a module script failed")
        ) {
          window.location.href = to.fullPath;
        }
      });
    }
    const i18n = await generateI18n();
    app.use(i18n);
    app.use(router);

    const queryClient = new QueryClient();
    app.use(VueQueryPlugin, {
      queryClient,
      // workaround, see https://github.com/vuejs/devtools-next/issues/317
      enableDevtoolsV6Plugin: true,
    });
    app.provide("queryClient", queryClient);

    app.use(FloatingVue);

    app.mount(el);
  }
};

initVue();
