/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { App } from '../models/App';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class AppsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * List of Apps
     * @returns App successful
     * @throws ApiError
     */
    public listApps(): CancelablePromise<Array<App>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/apps',
        });
    }
}
