<script setup lang="ts">
import { useRouter, RouterView } from "vue-router";

usePublicAccess();
useAxiosInterceptors();
useFlashMessages();
useRefetchUsers();
useOauthIframe();

const router = useRouter();

onMounted(async () => {
  await router.isReady();
  if (!router.currentRoute.value.name) {
    await router.replace({ name: "home" });
  }
});
const route = useRoute();
const { locale } = useI18n();

watch(
  () => route.query,
  async () => {
    if (route.query.locale) {
      locale.value = route.query.locale as string;
    }
  }
);
</script>

<template>
  <IncognitoOverlay />
  <PasswordPrompt>
    <RouterView />
  </PasswordPrompt>
</template>
