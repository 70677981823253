/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginatedPartnerEntries } from '../models/PaginatedPartnerEntries';
import type { PartnerEntry } from '../models/PartnerEntry';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class CmsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * List of Partner Entries
     * @returns PaginatedPartnerEntries successful
     * @throws ApiError
     */
    public getPartnerEntries(): CancelablePromise<PaginatedPartnerEntries> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/cms/partner_entries',
        });
    }
    /**
     * Partner Entry Details
     * @returns PartnerEntry successful - not published but admin
     * @throws ApiError
     */
    public getPartnerEntry({
        id,
    }: {
        id: number,
    }): CancelablePromise<PartnerEntry> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/cms/partner_entries/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `not found - not published`,
            },
        });
    }
}
