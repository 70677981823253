<script lang="ts">
export default {
  name: "BaseTextInput",
};
</script>

<script setup lang="ts">
export type Props = {
  name?: string;
  id?: string;
  label?: string | boolean;
  labelWeight?: "bold" | "semibold" | "medium";
  autofocus?: boolean;
  placeholder?: string;
  helpMessage?: string;
  disabled?: boolean;
  size?: "lg" | "md" | "sm";
  fullWidth?: boolean;
  modelValue: string | number | null | undefined;
  maxLength?: number;
  labelTooltip?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  v$?: any | null;
  prefixText?: string | null;
};

const props = withDefaults(defineProps<Props>(), {
  name: "",
  id: undefined,
  placeholder: "",
  helpMessage: "",
  label: false,
  labelWeight: "medium",
  size: "md",
  disabled: false,
  maxLength: undefined,
  fullWidth: false,
  v$: null,
  prefixText: null,
  labelTooltip: undefined,
});

const inputRef = ref<HTMLInputElement>();

const handleInputEvent = (event: Event) => {
  if (props.v$) {
    props.v$.$touch();
  }
  const target = event.target as HTMLButtonElement;
  emit("update:modelValue", target.value);
};

const isValid = computed(() => {
  return !(props.v$ && props.v$.$error);
});

const dirty = computed(() => props.v$ && props.v$.$dirty);

const handleBlurEvent = () => {
  if (props.v$) {
    props.v$.$touch();
  }
};

const handleChangeEvent = () => {
  if (props.v$) {
    props.v$.$touch();
  }
};

const focus = () => {
  if (inputRef.value) {
    inputRef.value.focus();
  }
};

defineExpose({
  focus,
});

const emit = defineEmits(["update:modelValue"]);
</script>

<template>
  <div
    :class="{
      'has-validation': dirty,
      'mb-3': !prefixText,
      'w-100': fullWidth,
    }"
  >
    <label
      v-if="label"
      class="form-label"
      :class="`form-label-${size} fw-${labelWeight}`"
      :for="id"
    >
      {{ label }}
      <i
        v-if="labelTooltip"
        v-tooltip="labelTooltip"
        class="bi bi-info-circle ms-1"
        style="vertical-align: text-top; font-size: 1rem"
      ></i>
    </label>
    <div :class="{ 'input-group': prefixText }">
      <span v-if="prefixText" class="input-group-text">{{ prefixText }}</span>
      <input
        :id="id"
        ref="inputRef"
        :autofocus="autofocus"
        v-bind="$attrs"
        :value="modelValue"
        :maxlength="maxLength"
        :disabled="disabled"
        class="form-control"
        :class="{ 'is-invalid': !isValid && dirty }"
        :name="name"
        :placeholder="placeholder"
        :data-cy="`input-${name}`"
        @blur="handleBlurEvent"
        @input="handleInputEvent"
        @change="handleChangeEvent"
      />
    </div>
    <div v-if="isValid && helpMessage" class="form-text">{{ helpMessage }}</div>
    <div
      v-if="!isValid"
      class="invalid-feedback d-flex"
      :data-cy="`input-error-${name}`"
    >
      {{ v$.$errors[0].$message }}
    </div>
  </div>
</template>
