/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { User } from '../models/User';
import type { UserInput } from '../models/UserInput';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class UsersService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Current User Details
     * @returns User successful
     * @throws ApiError
     */
    public getCurrentUser(): CancelablePromise<User> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/platform/users/current',
            errors: {
                401: `unauthorized`,
            },
        });
    }
    /**
     * Update Current User
     * @returns User successful
     * @throws ApiError
     */
    public updateCurrentUser({
        requestBody,
    }: {
        requestBody: {
            user?: UserInput;
        },
    }): CancelablePromise<User> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/platform/users/current',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `unauthorized`,
                404: `not found`,
            },
        });
    }
}
