<script lang="ts" setup>
const userStore = useUserStore();

const password = defineModel<string>();

const onSubmit = () => {
  userStore.itemPassword = password.value;
  userStore.itemPasswordRequired = false;
};

watch(
  () => userStore.itemPasswordRequired,
  () => {
    if (!userStore.itemPasswordRequired) {
      password.value = "";
    }
  }
);

const { t } = useI18n();
</script>

<script lang="ts">
export default {
  name: "PasswordPrompt",
};
</script>

<template>
  <slot
    v-if="userStore.itemPassword || !userStore.itemPasswordRequired"
    name="default"
  ></slot>
  <TheLayout v-else>
    <section
      class="container my-auto d-flex flex-column justify-content-center align-items-center"
    >
      <form @submit.prevent="onSubmit">
        <div v-if="userStore.itemPasswordErrorCount > 1" class="text-red-500">
          invalid
        </div>
        <BaseTextInput
          v-model="password"
          type="password"
          :label="t('labels.password')"
        />
        <BaseButton type="submit" class="w-100">{{
          t("labels.continue")
        }}</BaseButton>
      </form>
    </section>
  </TheLayout>
</template>
