/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginatedTextEditorDocuments } from '../models/PaginatedTextEditorDocuments';
import type { TextEditorDocument } from '../models/TextEditorDocument';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class TextEditorDocumentsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Create Document
     * @returns TextEditorDocument successful
     * @throws ApiError
     */
    public createTextEditorDocument({
        requestBody,
    }: {
        requestBody: {
            text_editor_document?: TextEditorDocument;
        },
    }): CancelablePromise<TextEditorDocument> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/text_editor/documents',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `unauthorized`,
            },
        });
    }
    /**
     * List of Documents
     * @returns PaginatedTextEditorDocuments successful
     * @throws ApiError
     */
    public listTextEditorDocuments(): CancelablePromise<PaginatedTextEditorDocuments> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/text_editor/documents',
            errors: {
                401: `unauthorized`,
            },
        });
    }
    /**
     * Delete Document
     * @returns void
     * @throws ApiError
     */
    public deleteTextEditorDocument({
        id,
    }: {
        id: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/text_editor/documents/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `unauthorized`,
                403: `forbidden`,
                404: `not found`,
            },
        });
    }
    /**
     * Get Document
     * @returns TextEditorDocument successful
     * @throws ApiError
     */
    public getTestEditorDocument({
        id,
        token,
        password,
    }: {
        id: string,
        token?: string,
        password?: string,
    }): CancelablePromise<TextEditorDocument> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/text_editor/documents/{id}',
            path: {
                'id': id,
            },
            query: {
                'token': token,
                'password': password,
            },
            errors: {
                400: `bad request`,
                401: `unauthorized`,
                403: `forbidden`,
                404: `not found`,
            },
        });
    }
    /**
     * Update Document
     * @returns TextEditorDocument successful
     * @throws ApiError
     */
    public updateTextEditorDocument({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: {
            text_editor_document?: TextEditorDocument;
        },
    }): CancelablePromise<TextEditorDocument> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/text_editor/documents/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `unauthorized`,
                403: `forbidden`,
                404: `not found`,
            },
        });
    }
}
