/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AiRun } from '../models/AiRun';
import type { AiRunInput } from '../models/AiRunInput';
import type { PaginatedAiRuns } from '../models/PaginatedAiRuns';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class AiRunsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Cancel AI Run
     * @returns any successful
     * @throws ApiError
     */
    public cancelAiRun({
        id,
    }: {
        id: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/ai/runs/{id}/cancel',
            path: {
                'id': id,
            },
            errors: {
                401: `unauthorized`,
                404: `not found`,
            },
        });
    }
    /**
     * Create AI Run
     * @returns AiRun successful
     * @throws ApiError
     */
    public createAiRun({
        requestBody,
    }: {
        requestBody: {
            run?: AiRunInput;
        },
    }): CancelablePromise<AiRun> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/ai/runs',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad request`,
                401: `unauthorized`,
            },
        });
    }
    /**
     * List of AI Runs
     * @returns PaginatedAiRuns successful
     * @throws ApiError
     */
    public listAiRuns(): CancelablePromise<PaginatedAiRuns> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/ai/runs',
            errors: {
                401: `unauthorized`,
            },
        });
    }
    /**
     * Delete AI Run Details
     * @returns void
     * @throws ApiError
     */
    public deleteAiRun({
        id,
    }: {
        id: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/ai/runs/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `unauthorized`,
                404: `not found`,
            },
        });
    }
    /**
     * AI Run Details
     * @returns AiRun successful
     * @throws ApiError
     */
    public getAiRun({
        id,
    }: {
        id: string,
    }): CancelablePromise<AiRun> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/ai/runs/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `unauthorized`,
                404: `not found`,
            },
        });
    }
    /**
     * Update AI Run
     * @returns AiRun successful
     * @throws ApiError
     */
    public updateAiRun({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: {
            run?: AiRunInput;
        },
    }): CancelablePromise<AiRun> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/ai/runs/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad request`,
                401: `unauthorized`,
                404: `not found`,
            },
        });
    }
    /**
     * Cancel AI Run
     * @returns any successful
     * @throws ApiError
     */
    public retryAiRun({
        id,
    }: {
        id: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/ai/runs/{id}/retry',
            path: {
                'id': id,
            },
            errors: {
                401: `unauthorized`,
                404: `not found`,
            },
        });
    }
}
