/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SearchResults } from '../models/SearchResults';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class SearchesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Search
     * @returns SearchResults successful
     * @throws ApiError
     */
    public search({
        query,
        limit = 5,
    }: {
        query?: string,
        limit?: number,
    }): CancelablePromise<SearchResults> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/platform/searches',
            query: {
                'query': query,
                'limit': limit,
            },
            errors: {
                401: `unauthorized`,
            },
        });
    }
}
