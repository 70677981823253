/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type FileInput = {
    id: string;
    storage: FileInput.storage;
    metadata: {
        size?: number | null;
        filename?: string;
        mime_type: string;
    };
};
export namespace FileInput {
    export enum storage {
        CACHE = 'cache',
        STORE = 'store',
    }
}

