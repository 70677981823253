/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OauthApplication } from '../models/OauthApplication';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class OAuthApplicationsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * OAuth Application Details
     * @returns OauthApplication successful
     * @throws ApiError
     */
    public getOauthApplication({
        id,
    }: {
        id: string,
    }): CancelablePromise<OauthApplication> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/platform/oauth_applications/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `unauthorized`,
                404: `not found`,
            },
        });
    }
}
