/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ImageAttachment } from '../models/ImageAttachment';
import type { ImageAttachmentInput } from '../models/ImageAttachmentInput';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class ImageAttachmentsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Create Image Attachment
     * @returns ImageAttachment successful
     * @throws ApiError
     */
    public createImageAttachment({
        requestBody,
    }: {
        requestBody: {
            imageAttachment?: ImageAttachmentInput;
        },
    }): CancelablePromise<ImageAttachment> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/image_attachments',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `unauthorized`,
                422: `unprocessable entity`,
            },
        });
    }
    /**
     * Delete Image Attachment
     * @returns void
     * @throws ApiError
     */
    public deleteImageAttachment({
        id,
    }: {
        id: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/image_attachments/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `unauthorized`,
                404: `not found`,
            },
        });
    }
    /**
     * Show Image Attachment
     * @returns ImageAttachment successful
     * @throws ApiError
     */
    public showImageAttachment({
        id,
    }: {
        id: string,
    }): CancelablePromise<ImageAttachment> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/image_attachments/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `not found`,
            },
        });
    }
}
