<template>
  <div
    v-if="userStore.isIncognito"
    class="incognito"
    data-test="hint-invitee-impersonation"
  >
    <div class="alert alert-warning p-2 flex-column">
      <p class="mb-2">
        {{ t("incognito.signedInAs") }}
        <br />
        <strong :title="name">{{ shortName }}</strong>
      </p>
      <button
        class="btn btn-outline-danger btn-sm"
        data-cy="button-stop_invitee_impersonation"
        @click="stop"
      >
        {{ t("incognito.stop") }}
      </button>
    </div>
  </div>
</template>

<script lang="ts" setup>
const { t } = useI18n();
const userStore = useUserStore();

const name = computed(() => {
  if (userStore.isPupil) {
    return userStore.user?.pseudonym || userStore.user?.displayName;
  }

  return userStore.user?.pseudonym || userStore.user?.displayName;
});

const shortName = computed(() => {
  return name.value?.substring(0, 30);
});

const { sessions: sessionsService } = useApiClient();

const router = useRouter();

// called when still in incognito mode and the state is filled with incognito user
const getRedirectTo = () => {
  if (userStore.isPupil && userStore.user?.firstProjectId) {
    return {
      name: "teacher-school-class-project-show",
      params: {
        id: userStore.user?.schoolClassId,
        projectId: userStore.user?.firstProjectId,
      },
    };
  } else if (userStore.isPupil) {
    // When longterm project, pupil incognito mode was clicked from the OffCanvas view
    return {
      name: "teacher-school-class-pupils",
      params: { id: userStore.user?.schoolClassId },
    };
  }

  return undefined;
};

const { refetchUser } = useSession();

const queryClient = useQueryClient();

const stop = async () => {
  try {
    await sessionsService.exitIncognitoMode();

    const redirectTo = getRedirectTo();
    const userId = userStore.user?.id;

    queryClient.removeQueries();

    await refetchUser();

    if (redirectTo) {
      router.push(redirectTo);
    } else {
      window.location.href = `/backoffice/users/${userId}`;
    }
  } catch (error) {
    console.error(error);
  }
};
</script>

<script lang="ts">
export default {
  name: "IncognitoOverlay",
};
</script>
