/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OwnerFile } from '../models/OwnerFile';
import type { OwnerFileInput } from '../models/OwnerFileInput';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class OwnerFilesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Create Owner File
     * @returns OwnerFile successful
     * @throws ApiError
     */
    public createOwnerFile({
        requestBody,
    }: {
        requestBody: {
            ownerFile?: OwnerFileInput;
        },
    }): CancelablePromise<OwnerFile> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/owner_files',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `unauthorized`,
            },
        });
    }
    /**
     * Delete Owner File
     * @returns void
     * @throws ApiError
     */
    public deleteOwnerFile({
        id,
    }: {
        id: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/owner_files/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `unauthorized`,
                404: `not found`,
            },
        });
    }
    /**
     * Show Owner File
     * @returns OwnerFile successful
     * @throws ApiError
     */
    public showOwnerFile({
        id,
    }: {
        id: string,
    }): CancelablePromise<OwnerFile> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/owner_files/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `not found`,
            },
        });
    }
}
