// Adds a loading bar at the top during page loads.
import { StandardError, type ApiError } from "@/services/fobizzToolsApi";
import { flash } from "@/utils/flash";
import { csrfToken } from "@/utils/meta";
import type { Axios, AxiosError } from "axios";
import axios, { isAxiosError } from "axios";
import NProgress from "nprogress";

export const useAxiosInterceptors = (axiosInstance: Axios = axios) => {
  const latencyThreshold = 200;
  let loading = false;

  NProgress.configure({ showSpinner: false });

  // Add a request interceptor
  axiosInstance.interceptors.request.use(
    (config) => {
      const userStore = useUserStore();

      loading = true;
      setTimeout(() => {
        if (loading) NProgress.start();
      }, latencyThreshold);

      config.headers["X-CSRF-Token"] = csrfToken();

      if (userStore.accessToken) {
        config.headers["X-Access-Token"] = userStore.accessToken;
      }

      if (userStore.publicToken) {
        config.headers["X-Public-Token"] = userStore.publicToken;
      }

      if (userStore.itemPassword) {
        config.headers["X-Password"] = encodeURIComponent(
          userStore.itemPassword
        );
      }

      if (userStore.visitorToken) {
        config.headers["X-Visitor-Token"] = userStore.visitorToken;
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // Add a response interceptor
  axiosInstance.interceptors.response.use(
    (response) => {
      loading = false;
      NProgress.done();
      return response;
    },
    (error: AxiosError | ApiError) => {
      loading = false;
      NProgress.done();

      console.error(error);

      if (isAxiosError(error)) {
        if (error.response && error.response.status === 400) {
          handlePasswordMissing(error.response.data as StandardError);
        }

        // check if authentication failed
        if (error.response && error.response.status === 401) {
          handleUnauthorized();
        } else if (error.response && error.response.status >= 500) {
          handleServerError(error.message);
        }
      } else {
        if (error.status === 400) {
          handlePasswordMissing(error.body as StandardError);
        }

        // check if authentication failed
        if (error.status === 401) {
          handleUnauthorized();
        } else if (error.status >= 500) {
          handleServerError(error.message);
        }
      }

      return Promise.reject(error);
    }
  );

  const handlePasswordMissing = (errorResponse: StandardError) => {
    if (errorResponse.error === "no_password_provided") {
      const userStore = useUserStore();

      userStore.itemPasswordErrorCount += 1;
      userStore.clearPassword();
      userStore.itemPasswordRequired = true;
    }
  };

  const handleUnauthorized = () => {
    const userStore = useUserStore();
    const { refetchUser } = useSession();

    if (userStore.isAuthenticated) {
      console.info("Api returned 401: deleting session...");
      userStore.reset();
      refetchUser();
    }
  };

  const handleServerError = (message: string) => {
    flash(FlashKindEnum.ALERT, message);
  };
};
