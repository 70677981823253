<script setup lang="ts">
import dataProtectionImageDe from "@/images/data_protection_de.png";
import dataProtectionImageEn from "@/images/data_protection_en.png";

withDefaults(
  defineProps<{
    withMainMargin?: boolean;
  }>(),
  {
    withMainMargin: true,
  }
);

const { t } = useI18n();

const userStore = useUserStore();

const dataProtectionImage = computed(() => {
  if (userStore.user?.international) {
    return dataProtectionImageEn;
  }

  return dataProtectionImageDe;
});
</script>

<template>
  <footer class="footer">
    <div class="container" :class="{ 'main-margin': withMainMargin }">
      <div class="footer__columns">
        <div>
          <div>
            <img
              class="footer__image"
              :alt="t('footer.alternativeText')"
              :src="dataProtectionImage"
              loading="lazy"
              width="160"
              height="77"
            />
          </div>
        </div>
        <div>
          <p>
            Made with ♥ for teachers in Germany by fobizz
            <br />
            <span>{{ t("footer.copyright") }}</span>
          </p>
          <p class="mb-0">
            {{ t("footer.inappropriateContent") }}
            <a href="mailto:support@fobizz.com">support@fobizz.com</a>.
          </p>
        </div>
        <div>
          <ul class="footer__list">
            <li>
              <a
                target="_blank"
                rel="noopener"
                href="https://plattform.fobizz.com/imprints"
                >{{ t("footer.imprint") }}</a
              >
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener"
                href="https://plattform.fobizz.com/data_protections"
                >{{ t("footer.privacyPolicy") }}</a
              >
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener"
                href="https://plattform.fobizz.com/terms_and_conditions"
                >{{ t("footer.tAndC") }}</a
              >
            </li>
          </ul>
        </div>
        <div>
          <ul class="footer__list">
            <li>
              <a href="/help">{{ t("footer.help") }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>

<style lang="scss">
.footer {
  display: flex;
  align-items: center;
  border-top: 1px solid $border-color;
  background-color: $color-white;
  padding: $spacer-4 0;
  font-size: 0.8rem;

  .container {
    padding: $spacer-2 $spacer-3;
  }

  .links {
    .separator {
      margin: 0 1rem;
    }
  }

  &__columns {
    display: grid;
    grid-template-columns: 24% 40% 18% 18%;

    @include media-breakpoint-down(lg) {
      grid-template-columns: 23% 40% 16% 16%;
      grid-gap: $spacer-2;
    }

    @include media-breakpoint-down(md) {
      grid-template-columns: 1fr;
      grid-gap: $spacer-3;
    }

    > * {
      align-self: start;
    }
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      padding: 0;
      margin-bottom: $spacer-1;
    }

    a {
      color: $body-color;
      text-decoration: none;
    }
  }

  .image {
    max-width: 100%;
    height: auto;

    @include media-breakpoint-down(md) {
      max-width: 120px;
    }
  }

  &--sm {
    padding: $spacer-2 0;
  }

  &--sm &__list {
    display: flex;
    gap: $spacer-2;
  }

  &__select {
    margin-bottom: 1rem;

    select {
      padding-left: 2.4rem;
      width: unset;
    }
  }

  &__select_icon {
    position: absolute;
    font-size: 1.1rem;
    top: 0.3rem;
    left: 0.8rem;
  }
}
</style>
