/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AiMiniToolRequest } from '../models/AiMiniToolRequest';
import type { AiMiniToolRequestInput } from '../models/AiMiniToolRequestInput';
import type { PaginatedAiMiniToolRequests } from '../models/PaginatedAiMiniToolRequests';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class AiMiniToolRequestsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Create AI MiniToolRequest
     * @returns AiMiniToolRequest successful
     * @throws ApiError
     */
    public createMiniToolRequest({
        miniToolId,
        requestBody,
    }: {
        miniToolId: string,
        requestBody: {
            miniToolRequest?: AiMiniToolRequestInput;
        },
    }): CancelablePromise<AiMiniToolRequest> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/ai/mini_tools/{mini_tool_id}/mini_tool_requests',
            path: {
                'mini_tool_id': miniToolId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad request`,
                401: `unauthorized`,
            },
        });
    }
    /**
     * Delete AI MiniToolRequest
     * @returns void
     * @throws ApiError
     */
    public deleteMiniToolRequest({
        id,
    }: {
        id: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/ai/mini_tool_requests/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `unauthorized`,
                404: `not found`,
            },
        });
    }
    /**
     * List of AI MiniToolRequests
     * @returns PaginatedAiMiniToolRequests successful
     * @throws ApiError
     */
    public listAiMiniToolRequests({
        page = 1,
        limit = 10,
    }: {
        page?: number,
        limit?: number,
    }): CancelablePromise<PaginatedAiMiniToolRequests> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/ai/mini_tool_requests',
            query: {
                'page': page,
                'limit': limit,
            },
            errors: {
                401: `unauthorized`,
            },
        });
    }
}
