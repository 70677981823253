/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Search } from '../models/Search';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class SearchService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Search
     * @returns Search successful
     * @throws ApiError
     */
    public search({
        limit = 5,
        query,
    }: {
        limit?: number,
        query?: string,
    }): CancelablePromise<Search> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/searches',
            query: {
                'limit': limit,
                'query': query,
            },
        });
    }
}
