/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PinboardBoard } from '../models/PinboardBoard';
import type { PinboardBoardInput } from '../models/PinboardBoardInput';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class PinboardService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Create Board
     * @returns PinboardBoard successful
     * @throws ApiError
     */
    public createPinboardBoard(): CancelablePromise<PinboardBoard> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/pinboard/boards',
            errors: {
                401: `unauthorized`,
            },
        });
    }
    /**
     * Delete Board
     * @returns void
     * @throws ApiError
     */
    public deletePinboardBoard({
        id,
    }: {
        id: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/pinboard/boards/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `unauthorized`,
                403: `forbidden`,
                404: `not found`,
            },
        });
    }
    /**
     * Board Details
     * @returns PinboardBoard successful
     * @throws ApiError
     */
    public getPinboardBoard({
        id,
    }: {
        id: string,
    }): CancelablePromise<PinboardBoard> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/pinboard/boards/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `unauthorized`,
                403: `forbidden`,
                404: `not found`,
            },
        });
    }
    /**
     * Update Board
     * @returns PinboardBoard successful
     * @throws ApiError
     */
    public updatePinboardBoard({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: {
            board?: PinboardBoardInput;
        },
    }): CancelablePromise<PinboardBoard> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/pinboard/boards/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `unauthorized`,
                403: `forbidden`,
                404: `not_found`,
            },
        });
    }
}
