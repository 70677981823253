/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AppSearchableEnum } from '../models/AppSearchableEnum';
import type { PaginatedItems } from '../models/PaginatedItems';
import type { PaginatedMaterials } from '../models/PaginatedMaterials';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class MaterialsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * List of Materials
     * @returns PaginatedMaterials successful
     * @throws ApiError
     */
    public listMaterials({
        page = 1,
        limit = 12,
        order,
        international,
        query,
        modelsArray,
        fobizzApproved,
        provider,
        subjectTypesArray,
        schoolTypesArray,
        gradeLevelsArray,
        ids,
        userIds,
    }: {
        page?: number,
        limit?: number,
        order?: string,
        international?: string,
        query?: string,
        modelsArray?: Array<string>,
        fobizzApproved?: string,
        provider?: string,
        subjectTypesArray?: Array<string>,
        schoolTypesArray?: Array<string>,
        gradeLevelsArray?: Array<string>,
        ids?: string,
        userIds?: string,
    }): CancelablePromise<PaginatedMaterials> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/materials',
            query: {
                'page': page,
                'limit': limit,
                'order': order,
                'international': international,
                'query': query,
                'models[]': modelsArray,
                'fobizz_approved': fobizzApproved,
                'provider': provider,
                'subject_types[]': subjectTypesArray,
                'school_types[]': schoolTypesArray,
                'grade_levels[]': gradeLevelsArray,
                'ids': ids,
                'user_ids': userIds,
            },
        });
    }
    /**
     * Search Materials
     * @returns PaginatedItems successful
     * @throws ApiError
     */
    public searchMaterials({
        model,
        page = 1,
        limit = 12,
        query,
    }: {
        model: AppSearchableEnum,
        page?: number,
        limit?: number,
        query?: string,
    }): CancelablePromise<PaginatedItems> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/materials/search',
            query: {
                'model': model,
                'page': page,
                'limit': limit,
                'query': query,
            },
            errors: {
                400: `bad request`,
                401: `unauthorized`,
            },
        });
    }
}
