/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ClassroomPages } from '../models/ClassroomPages';
import type { ClassroomScreenCard } from '../models/ClassroomScreenCard';
import type { ClassroomScreenCardInput } from '../models/ClassroomScreenCardInput';
import type { ClassroomScreenPage } from '../models/ClassroomScreenPage';
import type { ClassroomScreenPageInput } from '../models/ClassroomScreenPageInput';
import type { ClassroomScreenScreen } from '../models/ClassroomScreenScreen';
import type { PaginatedClassroomScreens } from '../models/PaginatedClassroomScreens';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class ClassroomScreenService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Create Card
     * @returns ClassroomScreenCard successful
     * @throws ApiError
     */
    public createClassroomScreenCard({
        pageId,
        requestBody,
    }: {
        pageId: string,
        requestBody: {
            response?: ClassroomScreenCardInput;
        },
    }): CancelablePromise<ClassroomScreenCard> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/teaching_board/pages/{pageId}/cards',
            path: {
                'pageId': pageId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `unauthorized`,
                403: `forbidden`,
                404: `not found`,
            },
        });
    }
    /**
     * List of Cards
     * @returns ClassroomPages successful
     * @throws ApiError
     */
    public listClassroomScreenCards({
        pageId,
    }: {
        pageId: string,
    }): CancelablePromise<ClassroomPages> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/teaching_board/pages/{pageId}/cards',
            path: {
                'pageId': pageId,
            },
            errors: {
                401: `unauthorized`,
                404: `not found`,
            },
        });
    }
    /**
     * Delete Card
     * @returns void
     * @throws ApiError
     */
    public deleteClassroomScreenCard({
        id,
    }: {
        id: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/teaching_board/cards/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `unauthorized`,
                403: `forbidden`,
                404: `not found`,
            },
        });
    }
    /**
     * Card Details
     * @returns ClassroomScreenCard successful
     * @throws ApiError
     */
    public getClassroomScreenCard({
        id,
    }: {
        id: string,
    }): CancelablePromise<ClassroomScreenCard> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/teaching_board/cards/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `unauthorized`,
                403: `forbidden`,
                404: `not found`,
            },
        });
    }
    /**
     * Update Card
     * @returns ClassroomScreenCard successful
     * @throws ApiError
     */
    public updateClassroomScreenCard({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: {
            response?: ClassroomScreenCardInput;
        },
    }): CancelablePromise<ClassroomScreenCard> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/teaching_board/cards/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `unauthorized`,
                403: `forbidden`,
                404: `not found`,
            },
        });
    }
    /**
     * Duplicate Card
     * @returns any successful
     * @throws ApiError
     */
    public duplicateCard({
        cardId,
    }: {
        cardId: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/teaching_board/cards/{cardId}/duplicate',
            path: {
                'cardId': cardId,
            },
        });
    }
    /**
     * Create Page
     * @returns ClassroomScreenPage successful
     * @throws ApiError
     */
    public createClassroomScreenPage({
        screenId,
        requestBody,
    }: {
        screenId: string,
        requestBody: {
            response?: ClassroomScreenPageInput;
        },
    }): CancelablePromise<ClassroomScreenPage> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/teaching_board/screens/{screenId}/pages',
            path: {
                'screenId': screenId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `unauthorized`,
                403: `forbidden`,
                404: `not found`,
            },
        });
    }
    /**
     * List of Pages
     * @returns ClassroomPages successful
     * @throws ApiError
     */
    public listClassroomScreenPages({
        screenId,
    }: {
        screenId: string,
    }): CancelablePromise<ClassroomPages> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/teaching_board/screens/{screenId}/pages',
            path: {
                'screenId': screenId,
            },
            errors: {
                401: `unauthorized`,
                404: `not found`,
            },
        });
    }
    /**
     * Delete Page
     * @returns void
     * @throws ApiError
     */
    public deleteClassroomScreenPage({
        id,
    }: {
        id: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/teaching_board/pages/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `unauthorized`,
                403: `forbidden`,
                404: `not found`,
            },
        });
    }
    /**
     * Page Details
     * @returns ClassroomScreenPage successful
     * @throws ApiError
     */
    public getClassroomScreenPage({
        id,
    }: {
        id: string,
    }): CancelablePromise<ClassroomScreenPage> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/teaching_board/pages/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `unauthorized`,
                403: `forbidden`,
                404: `not found`,
            },
        });
    }
    /**
     * Update Page
     * @returns ClassroomScreenPage successful
     * @throws ApiError
     */
    public updateClassroomScreenPage({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: {
            response?: ClassroomScreenPageInput;
        },
    }): CancelablePromise<ClassroomScreenPage> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/teaching_board/pages/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `unauthorized`,
                403: `forbidden`,
                404: `not found`,
            },
        });
    }
    /**
     * Move Page
     * @returns ClassroomScreenPage successful
     * @throws ApiError
     */
    public moveClassroomScreenPage({
        pageId,
        requestBody,
    }: {
        pageId: string,
        requestBody: {
            response?: ClassroomScreenPageInput;
        },
    }): CancelablePromise<ClassroomScreenPage> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/teaching_board/pages/{pageId}/move',
            path: {
                'pageId': pageId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `unauthorized`,
                403: `forbidden`,
                404: `not found`,
            },
        });
    }
    /**
     * Create Screen
     * @returns ClassroomScreenScreen successful
     * @throws ApiError
     */
    public createClassroomScreen(): CancelablePromise<ClassroomScreenScreen> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/teaching_board/screens',
            errors: {
                401: `unauthorized`,
            },
        });
    }
    /**
     * List of Screens
     * @returns PaginatedClassroomScreens successful
     * @throws ApiError
     */
    public listClassroomScreens({
        page = 1,
        limit = 10,
    }: {
        page?: number,
        limit?: number,
    }): CancelablePromise<PaginatedClassroomScreens> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/teaching_board/screens',
            query: {
                'page': page,
                'limit': limit,
            },
            errors: {
                401: `unauthorized`,
            },
        });
    }
    /**
     * Delete Screen
     * @returns void
     * @throws ApiError
     */
    public deleteClassroomScreen({
        id,
    }: {
        id: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/teaching_board/screens/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `unauthorized`,
                403: `forbidden`,
                404: `not found`,
            },
        });
    }
    /**
     * Screen Details
     * @returns ClassroomScreenScreen successful
     * @throws ApiError
     */
    public getClassroomScreen({
        id,
        token,
        publicToken,
        password,
    }: {
        id: string,
        token?: string,
        publicToken?: string,
        password?: string,
    }): CancelablePromise<ClassroomScreenScreen> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/teaching_board/screens/{id}',
            path: {
                'id': id,
            },
            query: {
                'token': token,
                'public_token': publicToken,
                'password': password,
            },
            errors: {
                400: `bad request`,
                403: `forbidden`,
                404: `not found`,
            },
        });
    }
    /**
     * Update Screen
     * @returns ClassroomScreenScreen successful
     * @throws ApiError
     */
    public updateClassroomScreen({
        id,
    }: {
        id: string,
    }): CancelablePromise<ClassroomScreenScreen> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/teaching_board/screens/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `unauthorized`,
                403: `forbidden`,
                404: `not found`,
            },
        });
    }
}
