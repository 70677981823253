/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AiSpeech } from '../models/AiSpeech';
import type { AiSpeechInput } from '../models/AiSpeechInput';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class AiSpeechesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Create AI Speech
     * @returns AiSpeech successful
     * @throws ApiError
     */
    public createAiSpeech({
        requestBody,
    }: {
        requestBody: {
            speech?: AiSpeechInput;
        },
    }): CancelablePromise<AiSpeech> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/ai/speeches',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `unauthorized`,
                422: `unprocessable entity`,
            },
        });
    }
    /**
     * AI Speech Details
     * @returns AiSpeech successful
     * @throws ApiError
     */
    public getAiSpeech({
        id,
    }: {
        id: string,
    }): CancelablePromise<AiSpeech> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/ai/speeches/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `unauthorized`,
                403: `forbidden`,
                404: `not found`,
            },
        });
    }
}
