/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AiSpeechRecognition } from '../models/AiSpeechRecognition';
import type { AiSpeechRecognitionInput } from '../models/AiSpeechRecognitionInput';
import type { PaginatedAiSpeechRecognitions } from '../models/PaginatedAiSpeechRecognitions';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class AiSpeechRecognitionsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Create AI SpeechRecognition
     * @returns AiSpeechRecognition successful
     * @throws ApiError
     */
    public createSpeechRecognition({
        requestBody,
    }: {
        requestBody: {
            speechRecognition?: AiSpeechRecognitionInput;
        },
    }): CancelablePromise<AiSpeechRecognition> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/ai/speech_recognitions',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad request`,
                401: `unauthorized`,
                422: `unprocessable entity`,
            },
        });
    }
    /**
     * List of AI SpeechRecognition
     * @returns PaginatedAiSpeechRecognitions successful
     * @throws ApiError
     */
    public listAiSpeechRecognition({
        page = 1,
        limit = 10,
        query,
    }: {
        page?: number,
        limit?: number,
        query?: string,
    }): CancelablePromise<PaginatedAiSpeechRecognitions> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/ai/speech_recognitions',
            query: {
                'page': page,
                'limit': limit,
                'query': query,
            },
            errors: {
                401: `unauthorized`,
            },
        });
    }
    /**
     * Delete AI SpeechRecognition
     * @returns void
     * @throws ApiError
     */
    public deleteSpeechRecognition({
        id,
    }: {
        id: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/ai/speech_recognitions/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `unauthorized`,
                404: `not found`,
            },
        });
    }
    /**
     * AI SpeechRecognition Details
     * @returns AiSpeechRecognition successful
     * @throws ApiError
     */
    public getAiSpeechRecognition({
        id,
    }: {
        id: string,
    }): CancelablePromise<AiSpeechRecognition> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/ai/speech_recognitions/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `unauthorized`,
                404: `not found`,
            },
        });
    }
}
