/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AiDocumentQa } from '../models/AiDocumentQa';
import type { AiDocumentQaInput } from '../models/AiDocumentQaInput';
import type { PaginatedAiDocumentQas } from '../models/PaginatedAiDocumentQas';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class AiDocumentQasService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Create AI DocumentQa
     * @returns AiDocumentQa successful
     * @throws ApiError
     */
    public createDocumentQa({
        requestBody,
    }: {
        requestBody: {
            documentQa?: AiDocumentQaInput;
        },
    }): CancelablePromise<AiDocumentQa> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/ai/document_qas',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad request`,
                401: `unauthorized`,
            },
        });
    }
    /**
     * List of AI DocumentQa
     * @returns PaginatedAiDocumentQas successful
     * @throws ApiError
     */
    public listAiDocumentQa({
        page = 1,
        limit = 10,
        query,
    }: {
        page?: number,
        limit?: number,
        query?: string,
    }): CancelablePromise<PaginatedAiDocumentQas> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/ai/document_qas',
            query: {
                'page': page,
                'limit': limit,
                'query': query,
            },
            errors: {
                401: `unauthorized`,
            },
        });
    }
    /**
     * Delete AI DocumentQa
     * @returns void
     * @throws ApiError
     */
    public deleteDocumentQa({
        id,
    }: {
        id: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/ai/document_qas/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `unauthorized`,
                404: `not found`,
            },
        });
    }
    /**
     * AI DocumentQa Details
     * @returns AiDocumentQa successful
     * @throws ApiError
     */
    public getAiDocumentQa({
        id,
    }: {
        id: string,
    }): CancelablePromise<AiDocumentQa> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/ai/document_qas/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `unauthorized`,
                404: `not found`,
            },
        });
    }
}
