/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { User } from '../models/User';
import type { UserInput } from '../models/UserInput';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class UsersService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * List of Partner Users
     * @returns User successful with international filter
     * @throws ApiError
     */
    public listApprovedUsers({
        international,
    }: {
        international?: string,
    }): CancelablePromise<Array<User>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/users/approved',
            query: {
                'international': international,
            },
        });
    }
    /**
     * Current User Details
     * @returns User successful
     * @throws ApiError
     */
    public getCurrentUser(): CancelablePromise<User> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/users/current',
        });
    }
    /**
     * Update User
     * @returns User successful
     * @throws ApiError
     */
    public updateUser({
        userId,
        requestBody,
    }: {
        userId: string,
        requestBody: {
            user?: UserInput;
        },
    }): CancelablePromise<User> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/users/{userId}',
            path: {
                'userId': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `unauthorized`,
            },
        });
    }
}
