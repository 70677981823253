/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AiImage } from '../models/AiImage';
import type { AiImageInput } from '../models/AiImageInput';
import type { PaginatedAiImages } from '../models/PaginatedAiImages';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class AiImagesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Create AI Image
     * @returns AiImage successful
     * @throws ApiError
     */
    public createImage({
        requestBody,
    }: {
        requestBody: {
            image?: AiImageInput;
        },
    }): CancelablePromise<AiImage> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/ai/images',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad request`,
                401: `unauthorized`,
                422: `unprocessable entity`,
            },
        });
    }
    /**
     * List of AI Images
     * @returns PaginatedAiImages successful
     * @throws ApiError
     */
    public listAiImages({
        page = 1,
        limit = 10,
        query,
    }: {
        page?: number,
        limit?: number,
        query?: string,
    }): CancelablePromise<PaginatedAiImages> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/ai/images',
            query: {
                'page': page,
                'limit': limit,
                'query': query,
            },
            errors: {
                401: `unauthorized`,
            },
        });
    }
    /**
     * Delete AI Assistant
     * @returns void
     * @throws ApiError
     */
    public deleteAiImage({
        id,
    }: {
        id: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/ai/images/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `unauthorized`,
                404: `not found`,
            },
        });
    }
    /**
     * AI Image Details
     * @returns AiImage successful
     * @throws ApiError
     */
    public getAiImage({
        id,
    }: {
        id: string,
    }): CancelablePromise<AiImage> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/ai/images/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `unauthorized`,
                404: `not found`,
            },
        });
    }
}
