/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AudioRecorderFile } from '../models/AudioRecorderFile';
import type { AudioRecorderInput } from '../models/AudioRecorderInput';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class AudioRecorderService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * File Details
     * @returns AudioRecorderFile successful
     * @throws ApiError
     */
    public getAudioRecorderFile({
        id,
        token,
        password,
    }: {
        id: string,
        token?: string,
        password?: string,
    }): CancelablePromise<AudioRecorderFile> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/audio_recorder/files/{id}',
            path: {
                'id': id,
            },
            query: {
                'token': token,
                'password': password,
            },
            errors: {
                400: `bad request`,
                401: `unauthorized`,
                403: `forbidden`,
                404: `not found`,
            },
        });
    }
    /**
     * Update File
     * @returns AudioRecorderFile successful
     * @throws ApiError
     */
    public updateAudioRecorderFile({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: {
            audioRecorderFile?: AudioRecorderInput;
        },
    }): CancelablePromise<AudioRecorderFile> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/audio_recorder/files/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `unauthorized`,
                403: `forbidden`,
                404: `not found`,
            },
        });
    }
}
