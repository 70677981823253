/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AppEnum } from '../models/AppEnum';
import type { Item } from '../models/Item';
import type { ItemCreateInput } from '../models/ItemCreateInput';
import type { ItemInput } from '../models/ItemInput';
import type { ItemMoveInput } from '../models/ItemMoveInput';
import type { PaginatedItems } from '../models/PaginatedItems';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class ItemsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Create Item
     * @returns Item successful
     * @throws ApiError
     */
    public createItem({
        requestBody,
    }: {
        requestBody: ItemCreateInput,
    }): CancelablePromise<Item> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/items',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `unauthorized`,
                422: `unprocessable entity`,
            },
        });
    }
    /**
     * List of Items
     * @returns PaginatedItems successful
     * @throws ApiError
     */
    public listItems({
        objType,
        page = 1,
        limit = 10,
    }: {
        objType: AppEnum,
        page?: number,
        limit?: number,
    }): CancelablePromise<PaginatedItems> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/items',
            query: {
                'objType': objType,
                'page': page,
                'limit': limit,
            },
            errors: {
                401: `unauthorized`,
            },
        });
    }
    /**
     * Update Item
     * @returns Item successful
     * @throws ApiError
     */
    public updateItem({
        objId,
        objType,
        requestBody,
    }: {
        objId: string,
        objType: AppEnum,
        requestBody: ItemInput,
    }): CancelablePromise<Item> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/items',
            query: {
                'objId': objId,
                'objType': objType,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `unauthorized`,
                403: `forbidden`,
                404: `not found`,
            },
        });
    }
    /**
     * Duplicate Item
     * @returns void
     * @throws ApiError
     */
    public duplicateItem({
        objId,
        objType,
        tabId,
    }: {
        objId: string,
        objType: AppEnum,
        tabId?: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/items/duplicate',
            query: {
                'objId': objId,
                'objType': objType,
                'tabId': tabId,
            },
            errors: {
                401: `unauthorized`,
                403: `unauthorized`,
                404: `not found`,
            },
        });
    }
    /**
     * Move Item
     * @returns void
     * @throws ApiError
     */
    public moveItem({
        requestBody,
    }: {
        requestBody: ItemMoveInput,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/items/move',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `unauthorized`,
                403: `unauthorized`,
                404: `not found`,
            },
        });
    }
    /**
     * List of recently created Items
     * @returns Item successful
     * @throws ApiError
     */
    public listRecentlyCreatedItems({
        limit = 5,
    }: {
        limit?: number,
    }): CancelablePromise<Array<Item>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/items/recently_created',
            query: {
                'limit': limit,
            },
            errors: {
                401: `unauthorized`,
            },
        });
    }
    /**
     * Remix Item
     * @returns void
     * @throws ApiError
     */
    public remix({
        objId,
        objType,
        tabId,
    }: {
        objId: string,
        objType: AppEnum,
        tabId?: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/items/remix',
            query: {
                'objId': objId,
                'objType': objType,
                'tabId': tabId,
            },
            errors: {
                401: `unauthorized`,
                403: `unauthorized`,
                404: `not found`,
            },
        });
    }
    /**
     * Remix Item Sync
     * @returns Item successful
     * @throws ApiError
     */
    public remixSync({
        objId,
        objType,
    }: {
        objId: string,
        objType: AppEnum,
    }): CancelablePromise<Item> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/items/remix_sync',
            query: {
                'objId': objId,
                'objType': objType,
            },
            errors: {
                401: `unauthorized`,
                403: `unauthorized`,
                404: `not found`,
            },
        });
    }
    /**
     * Search Items
     * @returns PaginatedItems filter by discarded
     * @throws ApiError
     */
    public searchItems({
        folderId,
        query,
        discarded,
        page = 1,
        limit = 10,
    }: {
        folderId?: string,
        query?: string,
        discarded?: string,
        page?: number,
        limit?: number,
    }): CancelablePromise<PaginatedItems> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/items/search',
            query: {
                'folder_id': folderId,
                'query': query,
                'discarded': discarded,
                'page': page,
                'limit': limit,
            },
            errors: {
                401: `unauthorized`,
            },
        });
    }
    /**
     * Toggle Discarded Item
     * @returns void
     * @throws ApiError
     */
    public toggleDiscardedItem({
        objId,
        objType,
    }: {
        objId: string,
        objType: AppEnum,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/items/toggle_discard',
            query: {
                'objId': objId,
                'objType': objType,
            },
            errors: {
                401: `unauthorized`,
                403: `forbidden`,
                404: `not found`,
            },
        });
    }
    /**
     * Toggle Template Flag
     * @returns void
     * @throws ApiError
     */
    public toggleTemplateItem({
        objId,
        objType,
    }: {
        objId: string,
        objType: AppEnum,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/items/toggle_template',
            query: {
                'objId': objId,
                'objType': objType,
            },
            errors: {
                401: `unauthorized`,
                403: `forbidden`,
                404: `not found`,
                422: `unprocessable entity`,
            },
        });
    }
}
