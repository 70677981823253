import { type User as PlatformUser } from "@/services/fobizzPlatformApi";
import { type User } from "@/services/fobizzToolsApi";

export const useRefetchUsers = (opts?: { force?: boolean }) => {
  const route = useRoute();

  const userStore = useUserStore();

  const { apiClient } = useApiClient();
  const { apiClient: platformApiClient } = usePlatformApiClient();

  const shouldFetchUser = computed(() => {
    return (
      userStore.isAuthenticated &&
      (["home", "gallery-index", "folders-index"].includes(
        route.name as string
      ) ||
        opts?.force)
    );
  });

  const user = ref<User>();

  const fetchUser = async () => {
    try {
      user.value = await apiClient.users.getCurrentUser();
    } catch (error) {
      console.error(error);
    }
  };

  const platformUser = ref<PlatformUser>();

  const fetchPlatformUser = async () => {
    if (!userStore.isPlatformUser) {
      userStore.$patch({
        platformUser: undefined,
      });
      return;
    }

    try {
      platformUser.value = await platformApiClient.users.getCurrentUser();
    } catch (error) {
      console.error(error);
    }
  };

  const fetch = () => {
    if (shouldFetchUser.value) {
      fetchUser();
      fetchPlatformUser();
    }
  };

  onMounted(() => {
    fetch();
  });

  watch(
    () => route.name,
    () => {
      fetch();
    }
  );

  watch(
    () => user.value,
    () => {
      if (user.value) {
        userStore.$patch({
          user: user.value,
        });
      }
    }
  );

  watch(
    () => platformUser.value,
    () => {
      if (platformUser.value) {
        userStore.$patch({
          platformUser: platformUser.value,
        });
      }
    }
  );
};
