/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AiConversation } from '../models/AiConversation';
import type { AiConversationInput } from '../models/AiConversationInput';
import type { PaginatedAiConversations } from '../models/PaginatedAiConversations';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class AiConversationsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Create AI Conversation
     * @returns AiConversation successful
     * @throws ApiError
     */
    public createAiConversation({
        requestBody,
    }: {
        requestBody: {
            conversation?: AiConversationInput;
        },
    }): CancelablePromise<AiConversation> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/ai/conversations',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad request`,
                401: `unauthorized`,
            },
        });
    }
    /**
     * List of AI Conversations
     * @returns PaginatedAiConversations successful
     * @throws ApiError
     */
    public listAiConversations({
        page = 1,
        limit = 10,
        query,
        assistantCategory,
    }: {
        page?: number,
        limit?: number,
        query?: string,
        assistantCategory?: string,
    }): CancelablePromise<PaginatedAiConversations> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/ai/conversations',
            query: {
                'page': page,
                'limit': limit,
                'query': query,
                'assistantCategory': assistantCategory,
            },
            errors: {
                401: `unauthorized`,
            },
        });
    }
    /**
     * Delete AI Conversation
     * @returns void
     * @throws ApiError
     */
    public deleteAiConversation({
        id,
    }: {
        /**
         * ID of the conversation
         */
        id: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/ai/conversations/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `unauthorized`,
                404: `not found`,
            },
        });
    }
    /**
     * AI Conversation Details
     * @returns AiConversation successful
     * @throws ApiError
     */
    public getAiConversation({
        id,
    }: {
        /**
         * ID of the conversation
         */
        id: string,
    }): CancelablePromise<AiConversation> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/ai/conversations/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `unauthorized`,
                404: `not found`,
            },
        });
    }
    /**
     * Update AI Conversation
     * @returns AiConversation successful
     * @throws ApiError
     */
    public updateAiConversation({
        id,
        requestBody,
    }: {
        /**
         * ID of the conversation
         */
        id: string,
        requestBody: {
            conversation?: AiConversationInput;
        },
    }): CancelablePromise<AiConversation> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/ai/conversations/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `unauthorized`,
                404: `not found`,
            },
        });
    }
    /**
     * Search of AI Conversations
     * @returns PaginatedAiConversations successful
     * @throws ApiError
     */
    public searchAiConversations({
        page = 1,
        limit = 10,
        query,
    }: {
        page?: number,
        limit?: number,
        query?: string,
    }): CancelablePromise<PaginatedAiConversations> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/ai/conversations/search',
            query: {
                'page': page,
                'limit': limit,
                'query': query,
            },
            errors: {
                401: `unauthorized`,
            },
        });
    }
}
