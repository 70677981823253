import {
  type App,
  AppEnum,
  type AvailableProjectToolsEnum,
} from "@/services/fobizzToolsApi";
import { defineStore } from "pinia";

type AppsStoreType = {
  apps: App[];
};

export const useAppsStore = defineStore("apps", {
  state: (): AppsStoreType => {
    return {
      apps: [],
    };
  },
  getters: {
    findAppById:
      (store) =>
      (appId: AppEnum | AvailableProjectToolsEnum): App | undefined =>
        store.apps.find((app) => app.id === appId),
    findAppByNamespace:
      (store) =>
      (namespace: string): App | undefined =>
        store.apps.find((app) => app.namespace === namespace),
    list: (store) => store.apps,
    listCatalogable: (store) => store.apps.filter((app) => app.catalogable),
    listCatalogablePlusAssistant: (store) =>
      store.apps.filter(
        (app) => app.catalogable || app.id === AppEnum.AI_ASSISTANT
      ),
  },
});
