/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginatedWebsitePages } from '../models/PaginatedWebsitePages';
import type { WebsitePage } from '../models/WebsitePage';
import type { WebsitePageInput } from '../models/WebsitePageInput';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class WebsiteService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Create Page
     * @returns WebsitePage successful
     * @throws ApiError
     */
    public createWebsitePage(): CancelablePromise<WebsitePage> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/website/pages',
            errors: {
                401: `unauthorized`,
            },
        });
    }
    /**
     * List of Pages
     * @returns PaginatedWebsitePages successful
     * @throws ApiError
     */
    public listWebsitePages(): CancelablePromise<PaginatedWebsitePages> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/website/pages',
            errors: {
                401: `unauthorized`,
            },
        });
    }
    /**
     * Delete Page
     * @returns void
     * @throws ApiError
     */
    public deleteWebsitePage({
        id,
    }: {
        id: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/website/pages/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `unauthorized`,
                403: `forbidden`,
                404: `not found`,
            },
        });
    }
    /**
     * Page Details
     * @returns WebsitePage successful
     * @throws ApiError
     */
    public getWebsitePage({
        id,
        token,
        password,
    }: {
        id: string,
        token?: string,
        password?: string,
    }): CancelablePromise<WebsitePage> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/website/pages/{id}',
            path: {
                'id': id,
            },
            query: {
                'token': token,
                'password': password,
            },
            errors: {
                400: `bad request`,
                401: `unauthorized`,
                403: `forbidden`,
                404: `not found`,
            },
        });
    }
    /**
     * Update Page
     * @returns WebsitePage successful
     * @throws ApiError
     */
    public updateWebsitePage({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: {
            websitePage?: WebsitePageInput;
        },
    }): CancelablePromise<WebsitePage> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/website/pages/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `unauthorized`,
                403: `forbidden`,
                404: `not found`,
            },
        });
    }
}
