/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Course } from '../models/Course';
import type { CourseBookmarkInput } from '../models/CourseBookmarkInput';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class CoursesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Create Bookmark
     * @returns Course successful
     * @throws ApiError
     */
    public createCourseBookmark({
        requestBody,
    }: {
        requestBody: CourseBookmarkInput,
    }): CancelablePromise<Course> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/platform/course_bookmark',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `unauthorized`,
                404: `not found`,
            },
        });
    }
    /**
     * Delete Bookmark
     * @returns Course successful
     * @throws ApiError
     */
    public deleteCourseBookmark({
        requestBody,
    }: {
        requestBody: CourseBookmarkInput,
    }): CancelablePromise<Course> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/platform/course_bookmark',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `unauthorized`,
                404: `not found`,
            },
        });
    }
    /**
     * Course Details
     * @returns Course successful
     * @throws ApiError
     */
    public getCourse({
        id,
        userId,
    }: {
        id: number,
        userId?: number,
    }): CancelablePromise<Course> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/platform/courses/{id}',
            path: {
                'id': id,
            },
            query: {
                'userId': userId,
            },
            errors: {
                404: `not found`,
            },
        });
    }
}
