import { useOnboardingCheck } from "@/composables/useOnboardingCheck";
import { useUserStore } from "@/stores/user";
import ahoy from "ahoy.js";
import { RouteLocationNormalized, NavigationGuardNext } from "vue-router";

export async function routerBeforeEach(
  to: RouteLocationNormalized,
  _from: RouteLocationNormalized,
  next: NavigationGuardNext
): Promise<void> {
  const userStore = useUserStore();
  const { checkOnboarding } = useOnboardingCheck();

  if (checkOnboarding(to)) {
    next(false);
    return;
  }

  // redirect to school class if pupil
  if (
    userStore.isPupil &&
    userStore.user?.schoolClassId &&
    to.name === "home"
  ) {
    next({
      name: "pupil-school-class-show",
      params: { id: userStore.user.schoolClassId },
      query: { token: userStore.user.schoolClassToken },
    });

    return;
  }

  // analytics
  ahoy.trackView();

  // redirect to pupil page if page requires teacher
  if (to.meta.requiresTeacher && userStore.isPupil) {
    next({
      name: "home",
    });

    return;
  }

  // redirect to home if page requires pupil
  if (to.meta.requiresPupil && !userStore.isPupil) {
    next({
      name: "home",
    });

    return;
  }

  // redirect to login page if not logged in
  if (
    to.meta.requiresAuth &&
    !userStore.isAuthenticated &&
    !userStore.isVisitor &&
    !userStore.isPupil
  ) {
    next({
      name: "login",
      query: {
        redirectUri: window.location.href,
      },
    });
    return;
  }

  // enable galleryMode if URL contains public_token
  // => isGalleryMode disables items from being editable
  const galleryModeStore = useIsGalleryModeStore();
  if (to.query.public_token) {
    galleryModeStore.updateGalleryMode(true);
  } else {
    galleryModeStore.updateGalleryMode(false);
  }

  next();
}
