/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LinkShortenerInput } from '../models/LinkShortenerInput';
import type { LinkShortenerLink } from '../models/LinkShortenerLink';
import type { PaginatedLinkShortenerLinks } from '../models/PaginatedLinkShortenerLinks';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class LinkShortenerService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Create Link
     * @returns LinkShortenerLink successful
     * @throws ApiError
     */
    public createLinkShortenerLink(): CancelablePromise<LinkShortenerLink> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/link_shortener/links',
            errors: {
                401: `unauthorized`,
            },
        });
    }
    /**
     * List of Links
     * @returns PaginatedLinkShortenerLinks successful
     * @throws ApiError
     */
    public listLinkShortenerLinks(): CancelablePromise<PaginatedLinkShortenerLinks> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/link_shortener/links',
            errors: {
                401: `unauthorized`,
            },
        });
    }
    /**
     * Link Details
     * @returns LinkShortenerLink successful
     * @throws ApiError
     */
    public getLinkShortenerLink({
        id,
        token,
        password,
    }: {
        id: string,
        token?: string,
        password?: string,
    }): CancelablePromise<LinkShortenerLink> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/link_shortener/links/{id}',
            path: {
                'id': id,
            },
            query: {
                'token': token,
                'password': password,
            },
            errors: {
                400: `successful`,
                403: `forbidden`,
                404: `not found`,
            },
        });
    }
    /**
     * Update Link
     * @returns LinkShortenerLink successful
     * @throws ApiError
     */
    public updateLinkShortenerLink({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: {
            linkShortenerLink?: LinkShortenerInput;
        },
    }): CancelablePromise<LinkShortenerLink> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/link_shortener/links/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `unauthorized`,
                403: `forbidden`,
                404: `not found`,
                422: `unprocessable_entity`,
            },
        });
    }
}
