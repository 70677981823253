export const usePublicAccess = () => {
  const route = useRoute();

  const userStore = useUserStore();

  const checkTokens = () => {
    userStore.accessToken = route.query.token
      ? (route.query.token as string)
      : undefined;
    userStore.publicToken = route.query.public_token
      ? (route.query.public_token as string)
      : undefined;
    userStore.itemPassword = route.query.password
      ? (route.query.password as string)
      : undefined;
  };

  onMounted(() => {
    checkTokens();
  });

  watch(
    () => route.query,
    () => {
      checkTokens();
    },
    { deep: true }
  );

  return {
    checkTokens,
  };
};
